import React, { useState } from "react";
import "./header.css";
import "./header.scss";
import ConnectedWallet from "components/connected-wallet";
import IconLogo from "assets/img/logo-icon.png";

const Header = () => {
  const [modalswitch, setModalswitch] = useState(false);
  let switchModal = () => {
    if (modalswitch === false) {
      setModalswitch(true);
    } else {
      setModalswitch(false);
    }
  };
  return (
    <>
      <header className="header-wrap" style={{ zIndex: 5 }}>
        <div className="container-fluid d-flex px-2 justify-content-center justify-content-lg-start">
          <div className="row w-100">
            <div className="col-12">
              <div className="container-lg d-flex justify-content-between gap-3 align-items-center w-100">
                <div className="d-none d-lg-flex flex-column gap-2 text-start mx-3">
                  <h4
                    className="text-white header-greeting gradient-title"
                    style={{ fontSize: "23px", fontWeight: 600 }}
                  >
                    Greetings, YieldCat Customers!
                  </h4>
                  <span className="text-white headerdesc">
                    YieldCat is a revolutionary yield system for stable coins that is changing the game in the crypto market.
                  </span>
                </div>
                <a aria-current="page" className="active" href="/">
                  <img
                    src={IconLogo}
                    className="d-flex d-lg-none"
                    style={{ maxHeight: "40px" }}
                    alt=""
                  />
                </a>
                <div className="d-flex m-0 justify-content-between gap-3 align-items-center">
                  <div className="d-flex justify-content-between gap-3 align-items-center">
                    <ConnectedWallet />
                    {/* <div className="dropdown" onClick={switchModal}>
                      <button
                        aria-haspopup="true"
                        aria-expanded="false"
                        id="dropdown-basic-button2"
                        type="button"
                        className="dropdown-toggle btn btn-primary"
                      >
                        <div
                          className="d-flex align-items-center gap-2 position-relative"
                          style={{ bottom: "5px", fontSize: "12px" }}
                        >
                          <img
                            src="https://app.dypius.com/static/media/walletIcon.71c13b49.svg"
                            alt=""
                            className="position-relative"
                          />
                          <span className="connecttitle d-none d-lg-flex">
                            Connect Wallet
                          </span>
                        </div>
                      </button>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
