import React from "react";
import { Spinner } from "react-bootstrap";
// import "./pools.css";

const CustomButton = ({
    primary,
    transparent,
    style,
    className,
    label,
    onClick,
    icon,
    isDisabled,
    noGlow,
    isLoading,
    direction,
    animation = "border"    //grow
}) => {
    return (
        <button style={style} onClick={onClick} disabled={isDisabled} className={className}
        >
            {isLoading && (
                <Spinner
                    as="span"
                    animation={animation}
                    size="sm"
                    role="status"
                    aria-hidden="true"
                // style={{ marginRight: "10px" }}
                />
            )}
            {label}
        </button>
    );
};

export default CustomButton;
