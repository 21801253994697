import React from "react";
import Pools from "../dashboardMain/pools/pools";
import "./earn.css";
import EarnHero from "./earnHero/earnHero";
import Faq from "./faq/faq";

import StakingOptions from "./stakingOptions/stakingOptions";
import Header from "../../../components/dashboardComponents/layout/header/header";
import Footer from "../../../components/dashboardComponents/layout/footer/footer";

const MainBody = () => {
  return (
    <div className="main-panel pc">
      <Header />
      <div className="right-content pr-0 my-4 my-lg-5">
        <div className="container-lg earn-wrapper d-flex flex-column justify-content-center align-items-center position-relative">
          <EarnHero />
          <StakingOptions />
          <Pools />
          <Faq />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MainBody;
