import React from "react";
import BigNumber from "bignumber.js";

import { useReload } from "../../hooks/useReload";
import { useAsyncEffect } from "../../hooks/useAsyncEffect";
import { useWallet } from "../../wallets/wallet";
import Web3Contract from "../contract";
import { MAX_UINT_256, web3 } from "../utils";

import { useSelector } from "react-redux";
import { networkSelector } from "../../slices/network";

import abiJson from "web3/abi/usdc.json";

import {
    USDC_TOKEN_CONTRACT,
    CONTRACT_BSC_USDC
} from "../../config/const";

type ContractData = {
    balance: BigNumber;
    allowance: BigNumber;
};

export type USDCContract = ContractData & {
    contract: Web3Contract;
    reload(): void;
    approve: () => Promise<any>;
};

const InitialData: ContractData = {
    balance: new BigNumber(0),
    allowance: new BigNumber(0),
};

export function useUSDCContract(): USDCContract {
    const [reload] = useReload();
    const wallet = useWallet();
    const { network } = useSelector(networkSelector);

    const [data, setData] = React.useState<ContractData>(InitialData);
    const [contract, setContract] = React.useState<any>(
        new Web3Contract(
            network,
            // "web3/abi/busd.json",
            abiJson,
            USDC_TOKEN_CONTRACT(),
            "ERC20Token",
            wallet?.connector?.id
        )
    );

    useAsyncEffect(async () => {

        if (wallet.account) {
            let [balance, allowance] = await contract.batch([
                {
                    method: "balanceOf",
                    methodArgs: [wallet.account],
                },
                {
                    method: "allowance",
                    methodArgs: [wallet.account, CONTRACT_BSC_USDC],
                }
            ]);

            setData((prevState) => ({
                ...prevState,
                balance: balance,
                allowance: allowance,
            }));
        }
    }, [wallet, reload]);

    const approve = React.useCallback(async (): Promise<any> => {
        if (!wallet.account) {
            return Promise.reject();
        }

        const result = await contract.send(
            "approve",
            [CONTRACT_BSC_USDC, MAX_UINT_256.toString()],
            {
                from: wallet.account,
            },
            reload
        );
        return result;
    }, [reload, contract, wallet.account]);

    return React.useMemo<USDCContract>(
        () => ({
            ...data,
            contract,
            reload,
            approve
        }),
        [data, contract, reload, approve]
    );
}
