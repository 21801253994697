import React, { useEffect, useState } from "react";
import "./explore.css";
import BigNumber from "bignumber.js";
import { useWeb3Contracts } from "web3/contracts";
import axios from "axios";

const Explore = () => {
  const {
    stakingContract,
    trustYieldUSDC,
    trustYieldUSDT
  } = useWeb3Contracts();

  const [decimals, setDecimals] = useState(18);
  const [dailyReward, setDailyReward] = useState(1.5);
  const [totalInvestment, setTotalInvestment] = useState(0);
  const [totalInvestors, setTotalInvestors] = useState(0);
  const [totalPayout, setTotalPayout] = useState(0);

  const [totalInvestmentBUSD, setTotalInvestmentBUSD] = useState(0);
  const [totalInvestorsBUSD, setTotalInvestorsBUSD] = useState(0);
  const [totalPayoutBUSD, setTotalPayoutBUSD] = useState(0);

  const [totalInvestmentUSDC, setTotalInvestmentUSDC] = useState(0);
  const [totalInvestorsUSDC, setTotalInvestorsUSDC] = useState(0);
  const [totalPayoutUSDC, setTotalPayoutUSDC] = useState(0);

  const [decimalsUSDT, setDecimalsUSDT] = useState(6);
  const [totalInvestmentUSDT, setTotalInvestmentUSDT] = useState(0);
  const [totalInvestorsUSDT, setTotalInvestorsUSDT] = useState(0);
  const [totalPayoutUSDT, setTotalPayoutUSDT] = useState(0);


  useEffect(() => {
    setDailyReward(stakingContract.dailyReward * 100 / 1000);
  }, [stakingContract?.dailyReward]);

  useEffect(() => {
    setDecimals(stakingContract.decimals);
    setTotalInvestmentBUSD(new BigNumber(stakingContract.totalInvested).div(10 ** decimals).toNumber());
    setTotalInvestorsBUSD(stakingContract.totalInvestors);
    setTotalPayoutBUSD(new BigNumber(stakingContract.totalPayout).div(10 ** decimals).toNumber());
  }, [stakingContract?.decimals, stakingContract?.totalInvested, stakingContract?.totalInvestors, stakingContract?.totalPayout]);

  useEffect(() => {
    // setDecimals(trustYieldUSDC.decimals);
    setTotalInvestmentUSDC(new BigNumber(trustYieldUSDC.totalInvested).div(10 ** decimals).toNumber());
    setTotalInvestorsUSDC(trustYieldUSDC.totalInvestors);
    setTotalPayoutUSDC(new BigNumber(trustYieldUSDC.totalPayout).div(10 ** decimals).toNumber());

  }, [trustYieldUSDC.decimals, trustYieldUSDC.totalInvested, trustYieldUSDC.totalInvestors, trustYieldUSDC.totalPayout]);

  useEffect(() => {
    const _decimal = trustYieldUSDT.decimals;
    setDecimalsUSDT(_decimal);
    setTotalInvestmentUSDT(new BigNumber(trustYieldUSDT.totalInvested).div(10 ** _decimal).toNumber());
    setTotalInvestorsUSDT(trustYieldUSDT.totalInvestors);
    setTotalPayoutUSDT(new BigNumber(trustYieldUSDT.totalPayout).div(10 ** _decimal).toNumber());

  }, [trustYieldUSDT.decimals, trustYieldUSDT.totalInvested, trustYieldUSDT.totalInvestors, trustYieldUSDT.totalPayout]);

  useEffect(async () => {
    const resp = await axios.get(`${process.env.REACT_APP_API_URL}/monitor`);
    let po = 0;
    if (resp.status === 200) {
      po = resp.data.message.po;
    }

    setTotalPayout(totalPayoutBUSD + totalPayoutUSDC + totalPayoutUSDT + po);

  }, [totalPayoutBUSD, totalPayoutUSDC, totalPayoutUSDT]);

  useEffect(async () => {
    const resp = await axios.get(`${process.env.REACT_APP_API_URL}/monitor`);
    let tu = 0;
    if (resp.status === 200) {
      tu = resp.data.message.tu;
    }

    let _tt = parseInt(totalInvestorsBUSD) + parseInt(totalInvestorsUSDC) + parseInt(totalInvestorsUSDT);
    if (_tt > 0)
      _tt += tu;
    setTotalInvestors(_tt);
  }, [totalInvestorsBUSD, totalInvestorsUSDC, totalInvestorsUSDT])

  return (
    <div
      id="calculator"
      className="calculator-wrapper position-relative undefined"
    >
      <div className="purplediv" style={{ background: "rgb(142, 151, 205)" }} />
      <form>
        <div className="flex flex-column gap-2 justify-content-between">
          <div className="d-flex justify-content-between gap-2 align-items-center pb-4">
            <h6 className="explorercard-title gradient-title d-flex gap-2 align-items-center">
              <img
                src="https://app.dypius.com/static/media/zoom.b64c4f4b.svg"
                alt=""
              />{" "}
              Total Statistics
            </h6>
          </div>
          <div className="pills-container gap-3 d-flex justify-content-start row m-0 w-100 position-relative">
            <h6 className="explorercard-desc">
              YieldCat's dashboard monitors the total investment on all networks and the daily ROI remains stable as long as the user maintains regular activity.            </h6>
          </div>
          <div className="separator" />

          <div className="dashboard-stats">

            <div className="row w-100 gap-3 gap-lg-2 gap-xl-0 mx-0 justify-content-around align-items-center mt-4 mt-lg-5 position-relative calculator-chains-wrapper">

              <div className="dashboard-card">
                <div className="dashboard-stat-title">Daily ROI</div>
                <div className="dashboard-stat whopping-roi">{dailyReward}%</div>
              </div>
              <div className="dashboard-card">
                <div className="dashboard-stat-title">Total Investment</div>
                <div className="dashboard-stat money-smell">${(totalInvestmentBUSD + totalInvestmentUSDC + totalInvestmentUSDT).toFixed(0)}</div>
              </div>

            </div>

            <div className="row w-100 gap-3 gap-lg-2 gap-xl-0 mx-0 justify-content-around align-items-center mt-4 mt-lg-4 position-relative calculator-chains-wrapper">

              {/* <div className="dashboard-card">
                <div className="dashboard-stat-title">Total Payout</div>
                <div className="dashboard-stat money-smell">${totalPayout.toFixed(0)}</div>
              </div> */}
              <div className="dashboard-card">
                <div className="dashboard-stat-title">Referral Bonus</div>
                <div className="dashboard-stat whopping-roi">5%</div>
              </div>
              <div className="dashboard-card">
                <div className="dashboard-stat-title">Total Investors</div>
                <div className="dashboard-stat">{totalInvestors}</div>
              </div>

            </div>

          </div>

        </div>
      </form>
    </div>
  );
};

export default Explore;
