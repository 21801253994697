import React, { useState, useEffect } from "react";
import { useWeb3Contracts } from "web3/contracts";
import { useWallet } from "wallets/wallet"
import { makeReferralCode } from "helper/referral";
import { onCopyLink } from "helper/clipboard";
import BigNumber from "bignumber.js";

import "./referral.css";

import Header from "../../../components/dashboardComponents/layout/header/header";
import Footer from "../../../components/dashboardComponents/layout/footer/footer";

import ReferralPaidOut from "../earnComponents/referralPaidOut/index";


const ReferralComp = () => {

  const wallet = useWallet();
  const {
    stakingContract,
    trustYieldUSDC,
    trustYieldUSDT
  } = useWeb3Contracts();

  const [decimals, setDecimals] = useState(18);

  const [refLink, setRefLink] = useState(process.env.REACT_APP_DOMAIN);
  const [refEarningsBUSD, setRefEarningsBUSD] = useState(0);

  // USDC
  const [refEarningsUSDC, setRefEarningsUSDC] = useState(0);

  const [refEarningsUSDT, setRefEarningsUSDT] = useState(0);

  const [totalRefEarnings, setTotalRefEarnings] = useState(0);

  useEffect(() => {
    if (wallet.account) {
      setRefLink(`${process.env.REACT_APP_DOMAIN}?ref=${makeReferralCode(wallet.account)}`);
    } else {
      setRefLink(process.env.REACT_APP_DOMAIN);
      setRefEarningsBUSD(0);
      setRefEarningsUSDC(0);
      setRefEarningsUSDT(0);
    }
  }, [wallet]);

  useEffect(() => {
    setDecimals(stakingContract.decimals);
  }, [stakingContract?.decimals]);

  useEffect(() => {
    if (stakingContract.userInfo) {
      setRefEarningsBUSD(new BigNumber(stakingContract.userInfo.totalReferralEarnings ? stakingContract.userInfo.totalReferralEarnings : 0).div(10 ** decimals).toNumber());
    }

  }, [stakingContract.userInfo]);

  useEffect(() => {
    if (trustYieldUSDC.userInfo) {
      setRefEarningsUSDC(new BigNumber(trustYieldUSDC.userInfo.totalReferralEarnings ? trustYieldUSDC.userInfo.totalReferralEarnings : 0).div(10 ** decimals).toNumber());
    }

  }, [trustYieldUSDC.userInfo]);

  useEffect(() => {
    if (trustYieldUSDT.userInfo) {
      setRefEarningsUSDT(new BigNumber(trustYieldUSDT.userInfo.totalReferralEarnings ? trustYieldUSDT.userInfo.totalReferralEarnings : 0).div(10 ** decimals).toNumber());
    }

  }, [trustYieldUSDT.userInfo]);

  useEffect(() => {
    setTotalRefEarnings(refEarningsBUSD + refEarningsUSDC + refEarningsUSDT);

  }, [refEarningsBUSD, refEarningsUSDC, refEarningsUSDT]);

  useEffect(() => {
    if (stakingContract.banned) {
      setRefLink(process.env.REACT_APP_DOMAIN);
    }
    else {
    }
  }, [stakingContract.banned])

  return (
    <div className="main-panel pc">
      <Header />

      <section className="main-referral   pr-0 my-4 my-lg-5">
        <div className="right-content ">
          <div className="container-lg">
            <ReferralPaidOut />
            <div className="flex-cont top-component" style={{ marginTop: "30px" }}>
              <div className="flex-cont friend-block">
                <div color="#fff" className="top-head header-greeting">
                  Invite More Friends!
                </div>
                <div color="text" className="colored-text friend-desc">
                  The referral reward amount increases as the investor invites more friends to join the platform,
                  <br />
                  providing a lucrative opportunity to earn additional rewards for their participation.
                  <br />
                  <b>In order to qualify for the referral bonus, you must make at least one investment as a customer.</b>
                </div>
              </div>

            </div>
            <div className="flex-cont ref-block">
              <div className="white-bg flex-cont left-blk">
                <div
                  width="100%"
                  className="sc-pNWdM sc-jrsJWt full-width start-flx"
                >
                  <div
                    fontSize="1rem"
                    fontWeight={500}
                    color="deActive"
                    className="active-ref"
                  >
                    Your Referral Earnings
                  </div>
                </div>
                <div
                  fontSize="1.5rem"
                  fontWeight="bold"
                  color="text"
                  className="oreo-head"
                >
                  ${totalRefEarnings ? totalRefEarnings.toFixed(2) : 0} &nbsp;&nbsp;
                  {!wallet.account && (<span style={{ fontSize: "1rem" }}>(Connect your wallet)</span>)}
                </div>
                <p className="referral-earnings-caption">
                  Your referral earnings are automatically sent to the wallet linked with your referral link.
                </p>
                <div className="adjust-block" />
              </div>
              <div className="flex-cont link-block">
                <div
                  fontSize="24px"
                  fontWeight={500}
                  color="#fff"
                  className="link-head"
                >
                  My Referral Link
                </div>
                <div className="relative-p" style={{ marginTop: "1.4rem" }}>
                  <div className="flex-cont input-block">
                    {wallet.account ? (<div color="text" className="white-link  ">
                      {refLink}
                    </div>) : (<div color="text" className="white-link  ">
                      Connect your wallet.
                    </div>)}

                  </div>
                  <div className="copy-link-btn">
                    <button
                      className="inner-copy absolute-btn pancake-button--disabled"
                      scale="md"
                      fontWeight={700}
                      onClick={(e) => onCopyLink(e, refLink)}
                      disabled={wallet.account ? false : true}
                    >
                      <svg
                        viewBox="0 0 24 24"
                        color="#fff"
                        width="20px"
                        xmlns="http://www.w3.org/2000/svg"
                        className="sc-bdnxRM search-svg"
                      >
                        <path d="M15 1H4C2.9 1 2 1.9 2 3V16C2 16.55 2.45 17 3 17C3.55 17 4 16.55 4 16V4C4 3.45 4.45 3 5 3H15C15.55 3 16 2.55 16 2C16 1.45 15.55 1 15 1ZM19 5H8C6.9 5 6 5.9 6 7V21C6 22.1 6.9 23 8 23H19C20.1 23 21 22.1 21 21V7C21 5.9 20.1 5 19 5ZM18 21H9C8.45 21 8 20.55 8 20V8C8 7.45 8.45 7 9 7H18C18.55 7 19 7.45 19 8V20C19 20.55 18.55 21 18 21Z" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default ReferralComp;
