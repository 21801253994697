import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { NavLinkWithQuery } from "helper/NavLinkWithQuery";
import IconLogo from "../../../../assets/img/logo-icon.png";
import Logo from "../../../../assets/img/logo.png";
import DocIcon from "../../../../assets/img/doc-icon.png";
import ShieldIcon from "../../../../assets/img/shield-icon.png";
import AffiliateIcon from "../../../../assets/img/affiliate-icon.png";
import StrategyIcon from "../../../../assets/img/brain-icon.png";
import "./sidebar.css";

const Sidebar = () => {
  const [switchhover, setSwitchhover] = useState(true);
  let toggleIt = () => {
    if (switchhover == false) {
      setSwitchhover(true);
    } else {
      setSwitchhover(false);
    }
  };
  let newfunc = () => {
    alert("OK");
    var headID = document.getElementsByTagName("head")[0];
    var newScript = document.createElement("script");
    newScript.type = "text/javascript";
    newScript.src =
      "https://uploads-ssl.webflow.com/63a5add22cb21cb50d097ff1/js/webflow.b8495f8ab.js";
    headID.appendChild(newScript);
  };

  return (
    <>
      <div className={switchhover ? "testbar-open d-none d-lg-flex" : "testbar d-none d-lg-flex"}>
        <div
          id="sidaebar"
          // onMouseOver={toggleIt}
          className="  flex-column justify-content-between align-items-start"
          style={{ padding: "1.6rem 0px" }}
        >
          <div className="w-100">
            <div className="d-flex w-100 justify-content-center align-items-center pb-5">
              {!switchhover ? (<NavLinkWithQuery aria-current="page" onClick={newfunc} to="/">
                <img src={IconLogo} alt="" style={{ height: 65 }} />
              </NavLinkWithQuery>) : (<NavLinkWithQuery aria-current="page" onClick={newfunc} to="/">
                <img src={Logo} alt="" style={{ height: 60 }} />
              </NavLinkWithQuery>)}

            </div>
            <div
              className="sidebar-container w-100  mt-4 px-2 justify-content-center align-items-center d-flex flex-column gap-4"
              style={{ gap: 35 }}
            >
              <div
                className="w-100 accordion justify-content-center align-items-center d-flex flex-column gap-4"
                id="accordionExample2"
              >
                <NavLinkWithQuery to="/dashboard">
                  <div
                    className={!switchhover ? "sidebar-item gap-3 p-2 d-flex justify-content-center align-items-center null active-side-icon" : "sidebar-item gap-3 p-2 d-flex active-width justify-content-start align-items-center null active-side-link"}
                  >
                    {/* <img
                      src="https://app.dypius.com/static/media/explorerIconActive.6f790a46.svg"
                      alt=""
                      style={{ width: 32, height: 32 }}
                    /> 
                    {switchhover && (<h3 className="sideitem-text">Dashboard</h3>)}
*/}
                    <img
                      src="https://app.dypius.com/static/media/earnIconActive.5d327cf7.svg"
                      alt=""
                      style={{ width: 32, height: 32 }}
                    />
                    {switchhover && (<h3 className="sideitem-text">Earn</h3>)}

                  </div>
                </NavLinkWithQuery>
                {/* <NavLinkWithQuery to="/earn">
                  <div className={!switchhover ? "sidebar-item gap-3 p-2 d-flex justify-content-center align-items-center null active-side-icon" : "sidebar-item gap-3 p-2 d-flex active-width justify-content-start align-items-center null active-side-link"}>
                    <img
                      src="https://app.dypius.com/static/media/earnIconActive.5d327cf7.svg"
                      alt=""
                      style={{ width: 32, height: 32 }}
                    />
                    {switchhover && (<h3 className="sideitem-text">Earn</h3>)}
                  </div>
                </NavLinkWithQuery> */}
                <NavLinkWithQuery to="/referral">
                  <div
                    id="Swap"
                    className={!switchhover ? "sidebar-item gap-3 p-2 d-flex justify-content-center align-items-center null active-side-icon" : "sidebar-item gap-3 p-2 d-flex active-width justify-content-start align-items-center null active-side-link"}
                  >
                    <img
                      src={AffiliateIcon}
                      alt=""
                      style={{ width: 32, height: 32 }}
                    />
                    {switchhover && (<h3 className="sideitem-text">Affiliate</h3>)}
                  </div>
                </NavLinkWithQuery>

                <a
                  href="https://yieldcat-labs-organization.gitbook.io/whitepaper/product-guides/revenue-strategy"
                  target="_blank"
                >
                  <div
                    id="Results"
                    className={!switchhover ? "sidebar-item gap-3 p-2 d-flex justify-content-center align-items-center null" : "sidebar-item gap-3 p-2 d-flex active-width justify-content-start align-items-center null"}                  >
                    <img
                      src={StrategyIcon}
                      alt=""
                      style={{ width: 32, height: 32 }}
                    />
                    {switchhover && (<h3 className="sideitem-text">Revenue</h3>)}
                  </div>
                </a>

                <a
                  href="https://yieldcat-labs-organization.gitbook.io/whitepaper/"
                  target="_blank"
                >
                  <div
                    id="News"
                    className={!switchhover ? "sidebar-item gap-3 p-2 d-flex justify-content-center align-items-center null" : "sidebar-item gap-3 p-2 d-flex active-width justify-content-start align-items-center null"}
                  >
                    <img
                      src={DocIcon}
                      alt=""
                      style={{ width: 32, height: 32 }}
                    />
                    {switchhover && (<h3 className="sideitem-text">WhitePaper</h3>)}
                  </div>
                </a>

                <a
                  href="https://dessert.finance/audits/YieldCat-Audit.pdf"
                  target="_blank"
                >
                  <div
                    id="Audit"
                    className={!switchhover ? "sidebar-item gap-3 p-2 d-flex justify-content-center align-items-center null" : "sidebar-item gap-3 p-2 d-flex active-width justify-content-start align-items-center null"}
                  >
                    <img
                      src={ShieldIcon}
                      alt=""
                      style={{ width: 32, height: 32 }}
                    />
                    {switchhover && (<h3 className="sideitem-text">Audit</h3>)}
                  </div>
                </a>


                {!switchhover ? (<div className="collapse-div" onClick={toggleIt}>
                  <svg className="collapse-div-svg" role="presentation" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" aria-hidden="true" focusable="false"><path d="M7.293 8L1.496 2.204l.708-.708L8.707 8l-6.503 6.504-.708-.708L7.293 8z"></path><path d="M13.07 8L7.274 2.204l.707-.708L14.484 8l-6.503 6.504-.707-.708L13.07 8z"></path></svg>
                </div>) : (
                  <div
                    onClick={toggleIt}
                    className="hover-scale gap-3 p-2 d-flex active-width justify-content-start align-items-center null"
                  >
                    <div className="collapse-div" style={{ width: 32, height: 32 }}>
                      <svg className="collapse-div-svg" role="presentation" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" aria-hidden="true" focusable="false"><path d="M8.707 8l5.797 5.796-.707.708L7.293 8l6.504-6.504.707.708L8.707 8z"></path><path d="M2.93 8l5.796 5.796-.707.708L1.516 8l6.503-6.504.707.708L2.93 8z"></path></svg>
                    </div>
                    <h3 className="sideitem-text">Collapse</h3>

                  </div>
                )}

              </div>
            </div>
          </div>
        </div>


      </div>
    </>
  );
};

export default Sidebar;
