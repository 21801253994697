import triggerNotification from "helper/Notification";

const onCopyLink = (e, value) => {
    e.preventDefault();

    const el = document.createElement("textarea");
    el.value = value;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);

    triggerNotification("", "Copied referral link", "info");
  };

  export {
    onCopyLink
  }