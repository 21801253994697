import React from "react";
import "./footer.scss";
import "./footer.css";
import Logo from "../../../../assets/img/logo.png";
import {
  YC_CONTRACT
} from "../../../../config/const";

const Footer = () => {
  return (
    <div className="footer footer-pad container-fluid d-flex justify-content-center justify-content-lg-start">
      <div className="row w-100">
        <div className="col-1" />
        <div className="col-11">
          <div className="py-4 flex-column flex-lg-row nu-pad px-0 container-lg d-flex justify-content-between gap-3 align-items-start align-items-lg-center">
            <div className="d-flex flex-row flex-lg-column justify-content-between justify-content-lg-center align-items-center align-items-lg-start col-12 col-lg-6 gap-2">
              <a target="_blank" href="#">
                <img src={Logo} alt="Dypius" className="footer-logo mb-2" />
              </a>
            </div>
            <hr
              className="form-divider my-2 d-flex d-lg-none"
              style={{ height: "2px" }}
            />
            <div className="social-and-links d-flex align-items-end flex-column-reverse flex-lg-column justify-content-center gap-4">
              <div className="social-profile">
                <span className="mobile-footer-title d-flex d-lg-none mb-3">
                  Community
                </span>
                <ul>
                  <li className="no-mar">
                    <a
                      href="https://twitter.com/YieldCatLabs"
                      target="_blank"
                    >
                      <img
                        src="https://app.dypius.com/assets/img/Social/twitter.svg"
                        alt="Twitter"
                        height={24}
                        width={24}
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://t.me/yieldcatlabs"
                      target="_blank"
                    >
                      <img
                        src="https://app.dypius.com/assets/img/Social/telegram.svg"
                        alt="Telegram"
                        height={24}
                        width={24}
                      />
                    </a>
                  </li>
                  {/* <li>
                    <a
                      href="https://medium.com/@yieldcat.com"
                      target="_blank"
                    >
                      <img
                        src="https://app.dypius.com/assets/img/Social/medium.svg"
                        alt="Medium"
                        height={24}
                        width={24}
                      />
                    </a>
                  </li> */}
                </ul>
              </div>
              <hr
                className="form-divider my-2 d-flex d-lg-none w-100"
                style={{ height: "2px" }}
              />
              <div className="footer-menu">
                <span className="mobile-footer-title d-flex d-lg-none mb-3">
                  Links
                </span>
                <ul className="external-links">
                  <li>
                    <a className="discx" href={`https://arbiscan.io/address/${YC_CONTRACT()}`} target="_blank">
                      Verified Contract
                    </a>
                  </li>

                  <li>
                    <a className="discx" href="https://dessert.finance/audits/YieldCat-Audit.pdf" target="_blank">
                      Audit
                    </a>
                  </li>
                  <li>
                    <a className="discx" href="https://yieldcat-labs-organization.gitbook.io/whitepaper/disclaimer" target="_blank">
                      Disclaimer
                    </a>
                  </li>
                  <li>
                    <a href="mailto:business@yieldcat.com">Contact Us</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-1" />
      </div>
    </div>
  );
};

export default Footer;
