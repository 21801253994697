import React from "react";
import Header from "../../components/dashboardComponents/layout/header/header";
import MobileBar from "../../components/dashboardComponents/layout/mobileBar/mobileBar";
import Sidebar from "../../components/dashboardComponents/layout/sidebar/sidebar";
import ReferralComp from "../../components/dashboardComponents/referralComponents/referral";

const Referral = () => {
  return (
    <div>
      <div className="page_wrapper">
        <div className="content-wrapper container-fluid d-flex justify-content-center justify-content-lg-start">
          <div className="w-100 d-flex">
            <Sidebar />
            <ReferralComp />
          </div>
        </div>
        <MobileBar />

      </div>
    </div>
  );
};

export default Referral;
