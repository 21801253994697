import React from 'react';
import { useWallet } from '../wallets/wallet';
import Web3Contract from './contract';
import { BOAContract, useBOAContract } from './contracts/boa';
import { YCUSDCContract, useYCUSDCContract } from './contracts/YCUSDC';
import { YCUSDTContract, useYCUSDTContract } from './contracts/YCUSDT';
import { BUSDContract, useBUSDContract } from './contracts/busd';
import { USDCContract, useUSDCContract } from './contracts/usdc';
import { USDTContract, useUSDTContract } from './contracts/usdt';

import UserRejectedModal from '../components/user-rejected-modal';

export type Web3ContractsData = {
  busdContract: BUSDContract;
  usdcContract: USDCContract;
  usdtContract: USDTContract;
  stakingContract: BOAContract;
  trustYieldUSDC: YCUSDCContract;
  trustYieldUSDT: YCUSDTContract;
};

export type Web3Contracts = Web3ContractsData & {};

const Web3ContractsContext = React.createContext<Web3Contracts>({} as any);

export function useWeb3Contracts(): Web3Contracts {
  return React.useContext(Web3ContractsContext);
}

const Web3ContractsProvider: React.FunctionComponent = props => {
  const wallet = useWallet();
  const boaContract = useBOAContract();
  const busdContract = useBUSDContract();
  const usdcContract = useUSDCContract();
  const usdtContract = useUSDTContract();
  const trustYieldUSDC = useYCUSDCContract();
  const trustYieldUSDT = useYCUSDTContract();

  const [userRejectedVisible, setUserRejectedVisible] = React.useState<boolean>(false);

  React.useEffect(() => {
    const contracts = [
      boaContract.contract,
      busdContract.contract,
      usdcContract.contract,
      usdtContract.contract,
      trustYieldUSDC.contract,
      trustYieldUSDT.contract,
    ];

    function handleError(err: Error & { code: number }, contract: Web3Contract, { method }: any) {
      console.error(`${contract.name}:${method}`, { error: err });

      if (err.code === 4001) {
        setUserRejectedVisible(true);
      } else {
        // TODO: Add err notification
        console.log(err);
      }
    }

    contracts.forEach((contract: Web3Contract) => {
      contract.on('error', handleError);
    });

    return () => {
      contracts.forEach((contract: Web3Contract) => {
        contract.off('error', handleError);
      });
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    const contracts = [
      boaContract.contract,
      busdContract.contract,
      usdcContract.contract,
      usdtContract.contract,
      trustYieldUSDC.contract,
      trustYieldUSDT.contract,
    ];

    contracts.forEach(contract => {
      contract.setProvider(wallet.provider);
    });
  }, [wallet.provider]); // eslint-disable-line react-hooks/exhaustive-deps

  const value = {
    stakingContract: boaContract,
    busdContract: busdContract,
    usdcContract: usdcContract,
    usdtContract: usdtContract,
    trustYieldUSDC: trustYieldUSDC,
    trustYieldUSDT: trustYieldUSDT,
  };

  return (
    <Web3ContractsContext.Provider value={value}>
      <UserRejectedModal
        show={userRejectedVisible}
        onHide={() => setUserRejectedVisible(false)}
      />
      {props.children}
    </Web3ContractsContext.Provider>
  );
};

export default Web3ContractsProvider;
