import React from "react";
import BigNumber from "bignumber.js";

import { useReload } from "../../hooks/useReload";
import { useAsyncEffect } from "../../hooks/useAsyncEffect";
import { useWallet } from "../../wallets/wallet";
import Web3Contract from "../contract";

import { useSelector } from "react-redux";
import { networkSelector } from "../../slices/network";

import {
  CONTRACT_BSC_USDC
} from "../../config/const";

import abiJson from "web3/abi/bsc-usdc.json";
import { any } from "prop-types";

type BOAData = {
  totalInvestors: number;
  totalInvested: BigNumber;
  totalClaimed: BigNumber;
  totalReferralBonus: BigNumber;
  totalPayout: BigNumber;
  totalRefunded: BigNumber;
  totalCurrentAssets: BigNumber;
  dailyReward: number;
  DEPOSIT_MIN_AMOUNT: number;
  CLAIM_MIN_AMOUNT: number;
  decimals: number;
  reward: BigNumber;
  userInfo: any;
  nextClaimDate: number;
  banned: boolean;
};

export type YCUSDCContract = BOAData & {
  contract: Web3Contract;
  reload(): void;
  deposit: (
    invitor: string,
    amount: BigNumber,
  ) => Promise<any>;
  claim: () => Promise<any>;
  reinvest: () => Promise<any>;
};

const InitialData: BOAData = {
  totalInvestors: 0,
  totalInvested: new BigNumber(0),
  totalClaimed: new BigNumber(0),
  totalReferralBonus: new BigNumber(0),
  totalPayout: new BigNumber(0),
  totalRefunded: new BigNumber(0),
  totalCurrentAssets: new BigNumber(0),
  dailyReward: 15,
  DEPOSIT_MIN_AMOUNT: 20,
  CLAIM_MIN_AMOUNT: 10,
  decimals: 6,
  reward: new BigNumber(0),
  userInfo: any,
  nextClaimDate: 0,
  banned: false,
};

export function useYCUSDCContract(): YCUSDCContract {
  const [reload] = useReload();
  const wallet = useWallet();

  const { network } = useSelector(networkSelector);

  const [data, setData] = React.useState<BOAData>(InitialData);
  const [contract, setContract] = React.useState<any>(
    new Web3Contract(
      network,
      abiJson,
      CONTRACT_BSC_USDC,
      "YieldCat Contract",
      wallet?.connector?.id
    )
  );

  useAsyncEffect(async () => {
    const newContract = new Web3Contract(
      network,
      abiJson,
      CONTRACT_BSC_USDC,
      "YieldCat Contract",
      wallet?.connector?.id
    );

    setContract(newContract);

    // try {
    //   let [totalInvestors, totalInvested, totalClaimed, totalReferralBonus,
    //     totalRefunded, dailyReward, DEPOSIT_MIN_AMOUNT,
    //     CLAIM_MIN_AMOUNT, decimals] = await newContract.batch([
    //       {
    //         method: "totalInvestors",
    //       },
    //       {
    //         method: "totalInvested",
    //       },
    //       {
    //         method: "totalClaimed",
    //       },
    //       {
    //         method: "totalReferralBonus",
    //       },
    //       {
    //         method: "totalRefunded",
    //       },
    //       {
    //         method: "dailyReward",
    //       },
    //       {
    //         method: "DEPOSIT_MIN_AMOUNT",
    //       },
    //       {
    //         method: "CLAIM_MIN_AMOUNT",
    //       },
    //       {
    //         method: "decimals",
    //       },
    //     ]);
    //   if (dailyReward !== undefined)
    //     setData((prevState) => ({
    //       ...prevState,
    //       totalInvestors,
    //       totalInvested,
    //       totalClaimed,
    //       totalReferralBonus,
    //       totalPayout: new BigNumber(totalClaimed).plus(new BigNumber(totalReferralBonus)),
    //       totalRefunded,
    //       totalCurrentAssets: new BigNumber(totalInvested).minus(new BigNumber(totalClaimed)).minus(new BigNumber(totalRefunded)).minus(new BigNumber(totalReferralBonus)),
    //       dailyReward,
    //       DEPOSIT_MIN_AMOUNT,
    //       CLAIM_MIN_AMOUNT,
    //       decimals
    //     }));
    // } catch (e) {

    // }

  }, [reload, network]);

  useAsyncEffect(async () => {
    // if (wallet.account) {
    //   let [reward, userInfo, nextClaimDate, banned] = await contract.batch([
    //     {
    //       method: "pendingReward",
    //       methodArgs: [wallet.account],
    //     },
    //     {
    //       method: "userInfo",
    //       methodArgs: [wallet.account],
    //     },
    //     {
    //       method: "nextClaimDate",
    //       methodArgs: [wallet.account],
    //     },
    //     {
    //       method: "banned",
    //       methodArgs: [wallet.account],
    //     },
    //   ]);

    //   if (reward !== undefined)
    //     setData((prevState) => ({
    //       ...prevState,
    //       reward,
    //       userInfo,
    //       nextClaimDate,
    //       banned,
    //     }));
    // }
    // else {
    //   setData((prevState) => ({
    //     ...prevState,
    //     banned: false,
    //   }));
    // }
  }, [wallet.account, reload]);

  const deposit = React.useCallback(
    async (
      invitor: string,
      amount: BigNumber
    ): Promise<any> => {
      if (!wallet.account) {
        return Promise.reject();
      }

      const result = await contract.send(
        "deposit",
        [invitor, amount.toString()],
        {
          from: wallet.account,
        },
        reload
      );
      return result;
    },
    [reload, contract, wallet.account]
  );

  const claim = React.useCallback(
    async (): Promise<any> => {
      if (!wallet.account) {
        return Promise.reject();
      }

      const result = await contract.send(
        "claim",
        [],
        {
          from: wallet.account,
        },
        reload
      );
      return result;
    },
    [reload, contract, wallet.account]
  );

  const reinvest = React.useCallback(
    async (): Promise<any> => {
      if (!wallet.account) {
        return Promise.reject();
      }

      const result = await contract.send(
        "redeposit",
        [],
        {
          from: wallet.account,
        },
        reload
      );
      return result;
    },
    [reload, contract, wallet.account]
  );

  return React.useMemo<YCUSDCContract>(
    () => ({
      ...data,
      contract,
      reload,
      deposit,
      claim,
      reinvest
    }),
    [data, contract, reload, deposit, claim]
  );
}
