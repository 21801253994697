import React, { useState, useEffect } from "react";
import "./index.css";
import BUSDIcon from "../../../../assets/img/busd-icon.png";
import USDIcon from "../../../../assets/img/usd-icon.png";
import { Link } from "react-router-dom";
import { NavLinkWithQuery } from "helper/NavLinkWithQuery";
import BigNumber from "bignumber.js";

import { useWeb3Contracts } from "web3/contracts";
import { useWallet } from "wallets/wallet"

const MyEarnings = () => {
  const [dailyReward, setDailyReward] = useState(1.5);

  const [dailyRewardAmount, setDailyRewardAmount] = useState(0);
  const [totalDeposit, setTotalDeposit] = useState(0);
  const [totalClaimRewards, setTotalClaimRewards] = useState(0);
  const [totalUnClaimedRewards, setTotalUnClaimedRewards] = useState(0);
  const [totalReferralEarnings, setTotalReferralEarnings] = useState(0);

  // USDT
  const [dailyRewardUSDT, setDailyRewardUSDT] = useState(1.5);
  const [userInfoUSDT, setUserInfoUSDT] = useState(null);
  const [decimalsUSDT, setDecimalsUSDT] = useState(6);

  const {
    trustYieldUSDT
  } = useWeb3Contracts();

  useEffect(() => {
    setDailyRewardUSDT(trustYieldUSDT.dailyReward * 100 / 1000);
  }, [trustYieldUSDT?.dailyReward]);

  useEffect(() => {
    if (trustYieldUSDT.userInfo && trustYieldUSDT.userInfo.totalDeposited !== undefined) {
      const totalDeposited = new BigNumber(trustYieldUSDT.userInfo.totalDeposited).div(10 ** decimalsUSDT).toNumber();
      setUserInfoUSDT({
        totalDeposited: totalDeposited,
        totalReferralEarnings: new BigNumber(trustYieldUSDT.userInfo.totalReferralEarnings).div(10 ** decimalsUSDT).toNumber(),
        totalClaimedRewards: new BigNumber(trustYieldUSDT.userInfo.totalClaimedRewards).div(10 ** decimalsUSDT).toNumber(),
        unclaimedReward: new BigNumber(trustYieldUSDT.userInfo.unclaimedReward).div(10 ** decimalsUSDT).toNumber(),
        dailyRewards: totalDeposited * dailyRewardUSDT / 100
      });
    }
    else
      setUserInfoUSDT(null);
  }, [trustYieldUSDT.reward, trustYieldUSDT.userInfo]);

  useEffect(() => {
    if (userInfoUSDT) {
      setDailyRewardAmount(userInfoUSDT.dailyRewards);
      setTotalDeposit(userInfoUSDT.totalDeposited);
      setTotalClaimRewards(userInfoUSDT.totalClaimedRewards);
      setTotalUnClaimedRewards(userInfoUSDT.unclaimedReward);
      setTotalReferralEarnings(userInfoUSDT.totalReferralEarnings);
    }
  }, [userInfoUSDT]);

  return (
    <div
      id="calculator"
      className="calculator-wrapper position-relative undefined"
    >
      <div className="purplediv" style={{ background: "rgb(142, 151, 205)" }} />
      <form>
        <div className="flex flex-column gap-2 justify-content-between">
          <div className="d-flex justify-content-between gap-2 align-items-center pb-4">
            <h6 className="d-flex gap-2 align-items-center calc-title gradient-title">
              <img
                src="https://app.dypius.com/static/media/calculator.4f8a9142.svg"
                alt=""
              />{" "}
              My Earnings
            </h6>
            <img
              src="https://app.dypius.com/static/media/calculatorChart.64e79d2d.png"
              className="calculator-chart d-flex d-lg-none"
              alt=""
            />
          </div>
          <div className="pills-container gap-3 d-flex justify-content-start row m-0 w-100 position-relative">
            <img
              src="https://app.dypius.com/static/media/calculatorChart.64e79d2d.png"
              className="calculator-chart d-none d-lg-flex"
              alt=""
            />
            <h6 className="explorercard-desc">
              This represents my overall earnings, <br />which is the total sum of earnings across all the pools.
            </h6>
          </div>

          <div className="separator" />

          <div className="dashboard-stats">

            <div className="row w-100 gap-3 gap-lg-2 gap-xl-0 mx-0 justify-content-around align-items-center mt-4 mt-lg-5 position-relative calculator-chains-wrapper">

              <div className="myearnings-card">
                <div className="dashboard-stat-title">Daily ROI</div>
                <div className="dashboard-stat whopping-roi">{dailyReward}%</div>
              </div>
              <div className="myearnings-card">
                <div className="dashboard-stat-title">Total Deposit</div>
                <div className="dashboard-stat money-smell">${userInfoUSDT ? totalDeposit.toFixed(2) : 0}</div>
              </div>
              <div className="myearnings-card">
                <div className="dashboard-stat-title">Referral Earnings</div>
                <div className="dashboard-stat money-smell">${userInfoUSDT ? totalReferralEarnings.toFixed(2) : 0}</div>
              </div>

            </div>

            <div className="row w-100 gap-3 gap-lg-2 gap-xl-0 mx-0 justify-content-around align-items-center mt-4 mt-lg-4 position-relative calculator-chains-wrapper">

              <div className="myearnings-card">
                <div className="dashboard-stat-title">Daily Rewards</div>
                <div className="dashboard-stat money-smell">${userInfoUSDT ? dailyRewardAmount : 0}</div>
              </div>
              <div className="myearnings-card">
                <div className="dashboard-stat-title">Claimed Rewards</div>
                <div className="dashboard-stat money-smell">${userInfoUSDT ? totalClaimRewards.toFixed(2) : 0}</div>
              </div>
              <div className="myearnings-card">
                <div className="dashboard-stat-title">Unclaimed Rewards</div>
                <div className="dashboard-stat money-smell">${userInfoUSDT ? totalUnClaimedRewards.toFixed(2) : 0}</div>
              </div>

            </div>

          </div>

        </div>
      </form>
    </div>
  );
};

export default MyEarnings;
