import React from "react";
import Calculator from "./calculator/calculator";
import MyEarnings from "./myearnings";
import Explore from "./explore/explore";
import Footer from "../layout/footer/footer";
import Launchpad from "./launchpad/launchpad";
import "./mainBody.css";
import "./mainBody.scss";

import Header from "../../../components/dashboardComponents/layout/header/header";
import Faq from "../earnComponents/faq/faq";
import EarnHero from "../earnComponents/earnHero/earnHero";


import News from "./news/news";
import Pools from "./pools/pools";
const Innercontent = () => {
  return (
    <div className="main-panel pc">
      <Header />

      <div className="right-content pr-0 my-4 my-lg-5">
        <div className="container-lg dashboardwrapper">
          <div className="d-flex m-0 flex-column flex-xxl-row justify-content-between gap-4">
            <div className="d-flex flex-column gap-4 justify-content-between">
              <EarnHero />

              <div className="d-flex flex-column flex-lg-row m-0 gap-3 justify-content-between">
                {/* <Calculator /> */}
                <Explore />
                <MyEarnings />
              </div>

              <Pools />
            </div>{" "}
            {/* <Launchpad /> */}
          </div>
          <div className="col-12 d-flex flex-column flex-lg-row row-space gap-3">
            <div className="col-12 col-md-6"><Faq className="" /></div>
            <div className=""><News className="" /></div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Innercontent;
