import React, { useEffect, useState } from "react";
import "./index.css";
import BSCIcon from "../../../../assets/img/bsc-icon.svg";
import PolkaIcon from "../../../../assets/img/polka-icon.png";
import AptosIcon from "../../../../assets/img/aptos-icon.png";
import ArbitrumIcon from "../../../../assets/img/arbitrum-logo.png";
import AuroraIcon from "../../../../assets/img/aurora.png";
import EthIcon from "../../../../assets/img/eth-icon.png";
import LogoCoin from "../../../../assets/img/logo-coin.png";
import BigNumber from "bignumber.js";
import { useWeb3Contracts } from "web3/contracts";

const ReferralPaidOut = () => {

  const {
    stakingContract,
    trustYieldUSDC,
    trustYieldUSDT
  } = useWeb3Contracts();

  const [decimals, setDecimals] = useState(18);
  const [decimalsUSDC, setDecimalsUSDC] = useState(6);
  const [decimalsUSDT, setDecimalsUSDT] = useState(6);

  const [totalPayout, setTotalPayout] = useState(0);

  const [totalPayoutBUSD, setTotalPayoutBUSD] = useState(0);
  const [totalPayoutUSDC, setTotalPayoutUSDC] = useState(0);
  const [totalPayoutUSDT, setTotalPayoutUSDT] = useState(0);


  useEffect(() => {
    setDecimals(stakingContract.decimals);
  }, [stakingContract.decimals]);

  useEffect(() => {
    setTotalPayoutBUSD(new BigNumber(stakingContract.totalReferralBonus).div(10 ** decimals).toNumber());

  }, [stakingContract.totalReferralBonus]);

  useEffect(() => {
    setTotalPayoutUSDC(new BigNumber(trustYieldUSDC.totalReferralBonus).div(10 ** decimalsUSDC).toNumber());

  }, [trustYieldUSDC.totalReferralBonus]);

  useEffect(() => {
    setTotalPayoutUSDT(new BigNumber(trustYieldUSDT.totalReferralBonus).div(10 ** decimalsUSDT).toNumber());

  }, [trustYieldUSDT.totalReferralBonus]);

  useEffect(() => {
    setTotalPayout(totalPayoutBUSD + totalPayoutUSDC + totalPayoutUSDT);

  }, [totalPayoutBUSD, totalPayoutUSDC, totalPayoutUSDT]);

  return (
    <div className="row w-100 mx-0 flex-column flex-lg-row earn-hero gap-4 gap-lg-0 p-3 p-lg-4 justify-content-between">
      <div className="col-12 col-lg-5 px-0 px-lg-2 d-flex flex-column justify-content-center gap-3">
        <h6 className="text-white tsc primary-text gradient-title" style={{ whiteSpace: "pre" }}>
          YieldCat Referral Earning
        </h6>
        <p className="text-white tcxs">
          YieldCat offers an instant <a href="https://yieldcat-labs-organization.gitbook.io/whitepaper/product-guides/referral-bonus" target="_blank" style={{ color: "yellow" }}>referral bonus</a> feature to investors who refer their friends using their unique referral link. If the referred friend deposits coins into the platform, the investor will receive a bonus equivalent to 5% of the deposited amount instantly.        </p>
      </div>
      <div className="col-12 col-lg-6 px-0 px-lg-2 d-flex gap-0 gap-lg-5">
        <div className="d-flex flex-column align-items-start">
          <div className="d-flex flex-column paid-rewards">
            <p
              style={{
                fontSize: "9px",
                color: "rgb(247, 247, 252)",
                fontWeight: 600,
                fontSize: 16
              }}
            >
              Referral Paid Out
            </p>
            <span
              className="count-up"
              style={{
                fontSize: "19px",
                color: "rgb(247, 247, 252)",
                fontWeight: 600,
                textAlign: "start",
              }}
            >
              ${totalPayout.toFixed(0)}
            </span>
          </div>
          <img
            src="https://app.dypius.com/static/media/earnHeroStats.c9f53e39.webp"
            alt=""
            style={{ width: "230px", height: "80px" }}
          />
        </div>
        <div className="d-flex flex-column justify-content-between">
          <div className="d-flex justify-content-start align-items-center gap-2">
            <img
              src={ArbitrumIcon}
              alt=""
              className="network-icon"
            />
            <h4 style={{ color: "rgb(247, 247, 252)", fontWeight: 600, fontSize: 16 }}>
              Active
              {/* Open Soon */}
            </h4>
          </div>

          <div className="d-flex justify-content-start align-items-center gap-2">
            <img
              src={EthIcon}
              alt=""
              className="network-icon"
            />
            <h4 style={{ color: "rgb(247, 247, 252)", fontWeight: 600, fontSize: 16 }}>
              Coming
            </h4>
          </div>

          <div className="d-flex justify-content-start align-items-center gap-2">
            <img
              src={AuroraIcon}
              alt=""
              className="network-icon"
            />
            <h4 style={{ color: "rgb(247, 247, 252)", fontWeight: 600, fontSize: 16 }}>
              Coming
            </h4>
          </div>
        </div>
        <div className="position-relative d-none d-xxl-block">
          <img
            src={LogoCoin}
            alt=""
            className="coin"
          />
          <img
            src="https://app.dypius.com/static/media/coinBackground.25219c3d.webp"
            alt=""
            className="coin"
          />
        </div>
      </div>
    </div>
  );
};

export default ReferralPaidOut;
