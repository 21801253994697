const CountdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a complete state
    return <></>;
  } else {
    let remainingTime = "";
    if (days > 0)
      remainingTime = `${days}d : ${hours}h : ${minutes}m : ${seconds}s`;
    else {
      if (hours > 0) {
        remainingTime = `${hours}h : ${minutes}m : ${seconds}s`;
      } else {
        remainingTime = `${minutes}m : ${seconds}s`;
      }
    }
    // Render a countdown
    return (
      <span style={{ color: "greenyellow", fontSize: "14px" }}>
        {remainingTime}
      </span>
    );
  }
};

export default CountdownRenderer;
