import "./assets/css/style.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import Web3WalletProvider from "wallets/wallet";
import Web3ContractsProvider from "./web3/contracts";

import Home from "./pages/home/home";
import Dashboard from "./pages/dashboard/dashboard";
import Earn from "./pages/earn/earn";
import Referral from "./pages/referral/referral";

import rootReducer from "./slices";

function App() {
  const store = configureStore({ reducer: rootReducer });

  return (
    <Provider store={store}>
      <Web3WalletProvider>
        <Web3ContractsProvider>
          <BrowserRouter>
            <Routes>
              <Route path={"/"} element={<Dashboard />} />
              <Route path={"/dashboard"} element={<Dashboard />} />
              <Route path={"/earn"} element={<Earn />} />
              <Route path={"/referral"} element={<Referral />} />
            </Routes>
          </BrowserRouter>
          <ToastContainer />
        </Web3ContractsProvider>
      </Web3WalletProvider>
    </Provider>
  );
}

export default App;

/*
import "./assets/css/style.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";

import Web3WalletProvider from "wallets/wallet";
import Web3ContractsProvider from "./web3/contracts";

import { Web3ReactProvider } from '@web3-react/core';
import Web3ReactManager from './utils/Web3ReactManager';
import { getLibrary } from './utils'

import Home from "./pages/home/home";
import Dashboard from "./pages/dashboard/dashboard";
import Earn from "./pages/earn/earn";
import Referral from "./pages/referral/referral";

import rootReducer from "./slices";
import { RefreshContextProvider } from 'utils/RefreshContext';

function App() {
  const store = configureStore({ reducer: rootReducer });

  return (
    <Provider store={store}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <Web3ReactManager>
          <Web3ContractsProvider>
            <RefreshContextProvider>
              <BrowserRouter>
                <Routes>
                  <Route path={"/"} element={<Home />} />
                  <Route path={"/dashboard"} element={<Dashboard />} />
                  <Route path={"/earn"} element={<Earn />} />
                  <Route path={"/referral"} element={<Referral />} />
                </Routes>
              </BrowserRouter>
              <ToastContainer />
            </RefreshContextProvider>
          </Web3ContractsProvider>
        </Web3ReactManager>
      </Web3ReactProvider>
    </Provider>
  );
}

export default App;

*/