const makeReferralCode = (address) => {
    const enc = (Buffer.from(address).toString('base64'));
    // const enc = address;
    return enc;
}

const decodeReferralCode = (refCode) => {
    let dec = "0x0000000000000000000000000000000000000000";
    if (refCode) {
        dec = (Buffer.from(refCode, 'base64').toString('ascii'))
        // dec = refCode;
    }
    return dec;
}

export {
    makeReferralCode,
    decodeReferralCode
}