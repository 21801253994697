import Web3 from "web3";
import BigNumber from "bignumber.js";

// const DEFAULT_CONTRACT_PROVIDER = new Web3.providers.WebsocketProvider(
//   getWSRpcUrl()
// );
const DEFAULT_CONTRACT_PROVIDER = new Web3.providers.HttpProvider(
  getHttpsRpcUrl()
);

export const web3 = new Web3(DEFAULT_CONTRACT_PROVIDER);

export const MAX_UINT_256 = new BigNumber(2).pow(256).minus(1);
export const ZERO_BIG_NUMBER = new BigNumber(0);

export function getChainID(chainName: string = "bsc"): number {
  switch (chainName) {
    case "bsc":
      return Number(process.env.REACT_APP_WEB3_BSC_CHAIN_ID);
    case "polygon":
      return Number(process.env.REACT_APP_WEB3_MATIC_CHAIN_ID);
    default:
      return Number(process.env.REACT_APP_WEB3_CHAIN_ID);
  }
}

export function getWeb3(
  chainId: number = Number(process.env.REACT_APP_WEB3_CHAIN_ID)
) {
  return new Web3(new Web3.providers.HttpProvider(getHttpsRpcUrl(chainId)));
}

export function getWSRpcUrl(
  chainId: number = Number(process.env.REACT_APP_WEB3_CHAIN_ID)
): string {
  const WEB3_RPC_ID = String(process.env.REACT_APP_WEB3_RPC_ID);
  console.log("chainId: ", chainId);
  switch (chainId) {
    case 1:
      return `wss://mainnet.infura.io/ws/v3/${WEB3_RPC_ID}`;
    case 4:
      return `wss://rinkeby.infura.io/ws/v3/${WEB3_RPC_ID}`;
    case 56:
      // return `wss://bsc-ws-node.nariox.org:443`;
      return `wss://speedy-nodes-nyc.moralis.io/${process.env.REACT_APP_MORALIS_PRC_ID}/bsc/mainnet/ws`;
    case 97:
      return `wss://speedy-nodes-nyc.moralis.io/${process.env.REACT_APP_MORALIS_PRC_ID}/bsc/testnet/ws`;
    case 137:
      return `wss://speedy-nodes-nyc.moralis.io/${String(
        process.env.REACT_APP_POLYGON_PRC_ID
      )}/polygon/mainnet/ws`;
    case 80001:
      return `wss://mumbai.infura.io/ws/v3/${WEB3_RPC_ID}`;
    case 42161:
      return `https://arb1.arbitrum.io/rpc`;
    case 421613:
      return `https://goerli-rollup.arbitrum.io/rpc`;
    default:
      throw new Error(`Not supported chainId=${chainId}.`);
  }
}

export function getHttpsRpcUrl(
  chainId: number = Number(process.env.REACT_APP_WEB3_CHAIN_ID)
): string {
  const WEB3_RPC_ID = String(process.env.REACT_APP_WEB3_RPC_ID);

  console.log("chain id = ", chainId);

  switch (chainId) {
    case 1:
      return `https://mainnet.infura.io/v3/${WEB3_RPC_ID}`;
    case 4:
      return `https://rinkeby.infura.io/v3/${WEB3_RPC_ID}`;
    case 56:
      return `https://bsc-dataseed.binance.org`;
    // return `https://speedy-nodes-nyc.moralis.io/61fb8581c0a493eb5057571c/bsc/mainnet/archive`;
    case 97:
      // return `https://data-seed-prebsc-1-s1.binance.org:8545`;
      // return "https://data-seed-prebsc-2-s1.binance.org:8545"
      return "https://data-seed-prebsc-2-s3.binance.org:8545";
    case 137:
      // return `https://polygon-mainnet.infura.io/v3/${WEB3_RPC_ID}`;
      return `https://polygon-rpc.com`;
    case 80001:
      return `https://mumbai.infura.io/v3/${WEB3_RPC_ID}`;
    case 42161:
      return `https://arb1.arbitrum.io/rpc`;
    case 421613:
      return `https://goerli-rollup.arbitrum.io/rpc`;
    default:
      throw new Error(`Not supported chainId=${chainId}.`);
  }
}

export function getEtherscanTxUrl(
  txHash: string,
  chainId: number = Number(process.env.REACT_APP_WEB3_CHAIN_ID)
): string {
  switch (chainId) {
    case 1:
      return `https://etherscan.io/tx/${txHash}`;
    case 4:
      return `https://rinkeby.etherscan.io/tx/${txHash}`;
    case 56:
      return `https://bscscan.com/tx/${txHash}`;
    case 97:
      return `https://testnet.bscscan.com/tx/${txHash}`;
    case 137:
      return `https://polygonscan.com/tx/${txHash}`;
    case 80001:
      return `https://mumbai.polygonscan.com/tx/${txHash}`;
    case 42161:
      return `https://arbiscan.io/tx/${txHash}`;
    case 421613:
      return `https://goerli.arbiscan.io/tx/${txHash}`;
    default:
      throw new Error(`Not supported chainId=${chainId}.`);
  }
}

export function getEtherscanAddressUrl(
  address: string,
  chainId: number = Number(process.env.REACT_APP_WEB3_CHAIN_ID)
): string {
  switch (chainId) {
    case 1:
      return `https://etherscan.io/address/${address}`;
    case 4:
      return `https://rinkeby.etherscan.io/address/${address}`;
    case 56:
      return `https://bscscan.com/address/${address}`;
    case 97:
      return `https://testnet.bscscan.com/address/${address}`;
    case 137:
      return `https://polygonscan.com/address/${address}`;
    case 80001:
      return `https://mumbai.polygonscan.com/address/${address}`;
    case 42161:
      return `https://arbiscan.io/address/${address}`;
    case 421613:
      return `https://goerli.arbiscan.io/address/${address}`;
    default:
      throw new Error(`Not supported chainId=${chainId}.`);
  }
}

export function getNetworkName(chainId: number | undefined): string {
  switch (chainId) {
    case 1:
      return "Ethereum Mainnet";
    case 4:
      return "Rinkeby Test Network";
    case 56:
      return "BNB Smart Chain";
    case 97:
      return "BSC Testnet";
    case 137:
      return "Polygon";
    case 80001:
      return "Mumbai";
    case 42161:
      return "Arbitrum One";
    case 421613:
      return "Arbitrum Goerli";
    default:
      return "-";
  }
}

export function getExponentValue(decimals: number = 0): BigNumber {
  return new BigNumber(10).pow(decimals);
}

export function getHumanValue(
  value?: BigNumber,
  decimals: number = 0
): BigNumber | undefined {
  return value?.div(getExponentValue(decimals));
}

export function getNonHumanValue(
  value: BigNumber | number,
  decimals: number = 0
): BigNumber {
  return new BigNumber(value).multipliedBy(getExponentValue(decimals));
}

export function formatBigValue(
  value?: BigNumber,
  decimals: number = 4,
  defaultValue: string = "-",
  minDecimals: number | undefined = undefined
): string {
  return value
    ? new BigNumber(value.toFixed(decimals)).toFormat(minDecimals)
    : defaultValue;
}

export function formatUSDValue(
  value?: BigNumber,
  decimals: number = 2,
  minDecimals: number = decimals
): string {
  if (value === undefined) {
    return "-";
  }

  const val = BigNumber.isBigNumber(value) ? value : new BigNumber(value);
  const formattedValue = formatBigValue(val.abs(), decimals, "-", minDecimals);

  return val.isPositive() ? `$${formattedValue}` : `-$${formattedValue}`;
}

export function defaultFormatValue(value?: BigNumber): string {
  return formatBigValue(value, 2);
}

export function shortenAddr(addr: string, first: number = 6, last: number = 4) {
  return [String(addr).slice(0, first), String(addr).slice(-last)].join("...");
}

export function convertToNumber(value: BigNumber | undefined) {
  if (value) {
    return parseFloat(value.toString());
  }

  return 0;
}

export function convertTimestampToDateTime(timestamp: number) {
  return new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  }).format(timestamp * 1000);
}
