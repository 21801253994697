export const CONTRACT_BSC = "0xc40a14f90c78239204909eF9CaDa082214D8fbBA";
export const CONTRACT_BSC_USDC = "0x4C54a0815c0A776a3c110228Ba98892f9e6711F3";
// "0xc93D2d560Ba9a2C67D951460bd485405129d8905"; test

export const MULTI_SEND_CONTRACT = "0xaA7111d07DBdba0238B25621708A3a2E881dAbD2";

export function YC_CONTRACT() {
  const chainId = process.env.REACT_APP_WEB3_CHAIN_ID;

  switch (parseInt(chainId)) {
    case 1:
      return ``;
    case 4:
      return ``;
    case 56:
      return ``;
    case 97:
      return ``;
    case 137:
      return ``;
    case 80001:
      return ``;
    case 42161:
      return `0x5646bd5D82D42393876e03b8D6274787a9f01575`;  //arb = 0x6aff15e63517c8D9112629a009B543A52058a8b5
    case 421613:
      return `0x1F56c9C413f3A08D8A94854D59Ee2222221f4D75`;  //arb = 0xEacD4950B3E5C900A22cbbDCb45634F382F51742
    default:
      throw new Error(`Not supported Chain.`);
  }
}

export function USDT_TOKEN_CONTRACT() {
  const chainId = process.env.REACT_APP_WEB3_CHAIN_ID;

  switch (parseInt(chainId)) {
    case 1:
      return ``;
    case 4:
      return ``;
    case 56:
      return ``;
    case 97:
      return ``;
    case 137:
      return ``;
    case 80001:
      return ``;
    case 42161:
      return `0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9`;
    case 421613:
      return `0x857D7682bE903C5357b2Ed3A3db18A99D0f0376f`;
    default:
      throw new Error(`Not supported Chain.`);
  }
}

export function USDC_TOKEN_CONTRACT() {
  const chainId = process.env.REACT_APP_WEB3_CHAIN_ID;

  switch (parseInt(chainId)) {
    case 1:
      return ``;
    case 4:
      return ``;
    case 56:
      return ``;
    case 97:
      return ``;
    case 137:
      return ``;
    case 80001:
      return ``;
    case 42161:
      return `0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8`;
    case 421613:
      return `0x857D7682bE903C5357b2Ed3A3db18A99D0f0376f`;
    default:
      throw new Error(`Not supported Chain.`);
  }
}

export function DAI_TOKEN_CONTRACT() {
  const chainId = process.env.REACT_APP_WEB3_CHAIN_ID;

  switch (parseInt(chainId)) {
    case 1:
      return ``;
    case 4:
      return ``;
    case 56:
      return ``;
    case 97:
      return ``;
    case 137:
      return ``;
    case 80001:
      return ``;
    case 42161:
      return `0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1`;
    case 421613:
      return `0x857D7682bE903C5357b2Ed3A3db18A99D0f0376f`;
    default:
      throw new Error(`Not supported Chain.`);
  }
}