import React from "react";
import Header from "../../components/dashboardComponents/layout/header/header";
import "../../assets/css/main.css";
import "../../assets/css/theme.css";
import "../../assets/css/responsive.css";
import Sidebar from "../../components/dashboardComponents/layout/sidebar/sidebar";
import MobileBar from "../../components/dashboardComponents/layout/mobileBar/mobileBar";
import MainBody from "../../components/dashboardComponents/earnComponents/mainBody";

const Earn = () => {
  return (
    <div>
      <div className="page_wrapper">
        <div className="content-wrapper container-fluid d-flex justify-content-center justify-content-lg-start">
          <div className="w-100 d-flex">
            <Sidebar />
            <MainBody />
          </div>
        </div>
        <MobileBar />
      </div>
    </div>
  );
};

export default Earn;
