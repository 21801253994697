import React, { useState, useEffect } from "react";
import "./pools.css";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import BigNumber from "bignumber.js";
import ReactInterval from "react-interval";
import Countdown from "react-countdown";
import { useLocation } from "react-router-dom";

import NewRibbonIcon from "../../../../assets/img/ribbon-new.png";
import SoonRibbonIcon from "../../../../assets/img/ribbon-soon.png";
import BUSDIcon from "../../../../assets/img/busd-icon.png";
import DAIIcon from "../../../../assets/img/dai-icon.png";
import USDCIcon from "../../../../assets/img/usdc.png";
import USDTIcon from "../../../../assets/img/usdt.png";
import { ReactComponent as EllipseSvg } from "../../../../assets/svg/icons/ellipse.svg";
import { ReactComponent as EllipseSvgGreen } from "../../../../assets/svg/icons/ellipse-green.svg";

import ConnectedWallet from "components/connected-wallet";

import { useWeb3Contracts } from "web3/contracts";
import { useWallet } from "wallets/wallet"

import CountdownRenderer from "components/ui/CountdownRenderer";
import triggerNotification from "helper/Notification";
import { makeReferralCode } from "helper/referral";
import { onCopyLink } from "helper/clipboard";
import { decodeReferralCode } from "helper/referral"

import CustomButton from "./CustomButton";

import {
  CONTRACT_BSC_USDC,
  YC_CONTRACT
} from "../../../../config/const";

const Pools = () => {
  const [deposit1, setDeposit1] = useState(false);
  const [showButtonText1, setShowButtonText1] = useState("Deposit");

  // USDC
  const [deposit2, setDeposit2] = useState(false);
  const [showButtonText2, setShowButtonText2] = useState("Deposit");

  // USDT
  const [deposit3, setDeposit3] = useState(false);
  const [showButtonText3, setShowButtonText3] = useState("Deposit");

  const [modalswitch, setModalswitch] = useState(false);
  const [statm, setstatm] = useState(false);

  const wallet = useWallet();
  const {
    busdContract,
    usdcContract,
    usdtContract,
    stakingContract,
    trustYieldUSDC,
    trustYieldUSDT
  } = useWeb3Contracts();


  const [started, setStarted] = useState(true);
  const [startedUSDC, setStartedUSDC] = useState(false);
  const [startedBUSD, setStartedBUSD] = useState(false);
  const [activeTime, setActiveTime] = useState(1680879600);

  const [totalCurrentAssets, setTotalCurrentAssets] = useState(0);

  const [busdBalance, setBusdBalance] = useState(0);
  const [busdAllowance, setBusdAllowance] = useState(0);

  const [dailyReward, setDailyReward] = useState(1.5);
  const [depositMinAmount, setDepositMinAmount] = useState(20);
  const [claimMinAmount, setClaimMinAmount] = useState(20);
  const [decimals, setDecimals] = useState(18);

  const [depositAmount, setDepositAmount] = useState(0);

  const [reward, setReward] = useState(0);
  const [userInfo, setUserInfo] = useState(null);
  const [nextClaimDate, setNextClaimDate] = useState(0);
  const [isClaimable, setIsClaimable] = useState(false);

  const [refLink, setRefLink] = useState(process.env.REACT_APP_DOMAIN);

  // USDC
  const [statusDialogUSDC, setStatusDialogUSDC] = useState(false);

  const [totalCurrentAssetsUSDC, setTotalCurrentAssetsUSDC] = useState(0);

  const [balanceUSDC, setBalanceUSDC] = useState(0);
  const [allowanceUSDC, setAllowanceUSDC] = useState(0);

  const [dailyRewardUSDC, setDailyRewardUSDC] = useState(1.5);
  const [depositMinAmountUSDC, setDepositMinAmountUSDC] = useState(20);
  const [claimMinAmountUSDC, setClaimMinAmountUSDC] = useState(20);
  const [decimalsUSDC, setDecimalsUSDC] = useState(6);

  const [depositAmountUSDC, setDepositAmountUSDC] = useState(0);

  const [rewardUSDC, setRewardUSDC] = useState(0);
  const [userInfoUSDC, setUserInfoUSDC] = useState(null);
  const [nextClaimDateUSDC, setNextClaimDateUSDC] = useState(0);
  const [isClaimableUSDC, setIsClaimableUSDC] = useState(false);
  //

  // USDT
  const [statusDialogUSDT, setStatusDialogUSDT] = useState(false);

  const [totalCurrentAssetsUSDT, setTotalCurrentAssetsUSDT] = useState(0);

  const [balanceUSDT, setBalanceUSDT] = useState(0);
  const [allowanceUSDT, setAllowanceUSDT] = useState(0);

  const [dailyRewardUSDT, setDailyRewardUSDT] = useState(1.5);
  const [depositMinAmountUSDT, setDepositMinAmountUSDT] = useState(20);
  const [claimMinAmountUSDT, setClaimMinAmountUSDT] = useState(20);
  const [decimalsUSDT, setDecimalsUSDT] = useState(6);

  const [depositAmountUSDT, setDepositAmountUSDT] = useState(0);

  const [rewardUSDT, setRewardUSDT] = useState(0);
  const [userInfoUSDT, setUserInfoUSDT] = useState(null);
  const [nextClaimDateUSDT, setNextClaimDateUSDT] = useState(0);
  const [isClaimableUSDT, setIsClaimableUSDT] = useState(false);
  //

  const [pending, setPending] = useState(false);
  const [pendingAction, setPendingAction] = useState(null);
  const [disabledStyle, setDisabledStyle] = useState("btn outline-btn disabled-btn d-flex justify-content-center align-items-center gap-2");
  const [enabledStyle, setEnabledStyle] = useState("btn filledbtn d-flex justify-content-center align-items-center gap-2");

  const [timeout] = useState(30000);  //30000
  const [enabled, setEnabled] = useState(true);

  // USDC
  useEffect(() => {
    if (usdcContract.balance !== undefined)
      setBalanceUSDC(new BigNumber(usdcContract.balance).div(10 ** decimalsUSDC).toNumber());
  }, [usdcContract?.balance]);

  useEffect(() => {
    setAllowanceUSDC(
      new BigNumber(usdcContract.allowance).div(10 ** decimalsUSDC).toNumber()
    );

  }, [usdcContract?.allowance]);

  useEffect(() => {
    setDailyRewardUSDC(trustYieldUSDC.dailyReward * 100 / 1000);
    setDepositMinAmountUSDC(trustYieldUSDC.DEPOSIT_MIN_AMOUNT);
    setClaimMinAmountUSDC(trustYieldUSDC.CLAIM_MIN_AMOUNT);
    setDecimalsUSDC(trustYieldUSDC.decimals);
  }, [trustYieldUSDC?.dailyReward]);

  useEffect(() => {
    setTotalCurrentAssetsUSDC(new BigNumber(trustYieldUSDC.totalCurrentAssets).div(10 ** decimalsUSDC).toNumber());
  }, [trustYieldUSDC?.totalCurrentAssets]);

  useEffect(() => {
    const _reward = new BigNumber(trustYieldUSDC.reward).div(10 ** decimalsUSDC).toNumber();
    setRewardUSDC(_reward);

    if (trustYieldUSDC.userInfo) {
      const totalDeposited = new BigNumber(trustYieldUSDC.userInfo.totalDeposited).div(10 ** decimalsUSDC).toNumber();

      setUserInfoUSDC({
        totalDeposited: totalDeposited,
        totalReferralEarnings: new BigNumber(trustYieldUSDC.userInfo.totalReferralEarnings).div(10 ** decimalsUSDC).toNumber(),
        totalClaimedRewards: new BigNumber(trustYieldUSDC.userInfo.totalClaimedRewards).div(10 ** decimalsUSDC).toNumber(),
        unclaimedReward: new BigNumber(trustYieldUSDC.userInfo.unclaimedReward).div(10 ** decimalsUSDC).toNumber(),
        dailyRewards: totalDeposited * dailyRewardUSDC / 100
      });

      const _nextClaimDate = trustYieldUSDC.nextClaimDate;
      setNextClaimDateUSDC(_nextClaimDate);

      setIsClaimableUSDC(_nextClaimDate !== 0 && new Date().getTime() >= _nextClaimDate * 1000 && parseFloat(_reward) >= parseFloat(trustYieldUSDC.CLAIM_MIN_AMOUNT))
    }

  }, [trustYieldUSDC.reward, trustYieldUSDC.userInfo]);
  //

  // USDT
  useEffect(() => {
    setBalanceUSDT(new BigNumber(usdtContract.balance).div(10 ** decimalsUSDT).toNumber());
  }, [usdtContract?.balance]);

  useEffect(() => {
    setAllowanceUSDT(
      new BigNumber(usdtContract.allowance).div(10 ** decimalsUSDT).toNumber()
    );

  }, [usdtContract?.allowance]);

  useEffect(() => {
    setDailyRewardUSDT(trustYieldUSDT.dailyReward * 100 / 1000);
    setDepositMinAmountUSDT(trustYieldUSDT.DEPOSIT_MIN_AMOUNT);
    setClaimMinAmountUSDT(trustYieldUSDT.CLAIM_MIN_AMOUNT);
    setDecimalsUSDT(trustYieldUSDT.decimals);
  }, [trustYieldUSDT?.dailyReward]);

  useEffect(() => {
    setTotalCurrentAssetsUSDT(new BigNumber(trustYieldUSDT.totalCurrentAssets).div(10 ** decimalsUSDT).toNumber());
  }, [trustYieldUSDT?.totalCurrentAssets]);

  useEffect(() => {
    const _reward = new BigNumber(trustYieldUSDT.reward).div(10 ** decimalsUSDT).toNumber();
    setRewardUSDT(_reward);

    if (trustYieldUSDT.userInfo) {
      const totalDeposited = new BigNumber(trustYieldUSDT.userInfo.totalDeposited).div(10 ** decimalsUSDT).toNumber();

      setUserInfoUSDT({
        totalDeposited: totalDeposited,
        totalReferralEarnings: new BigNumber(trustYieldUSDT.userInfo.totalReferralEarnings).div(10 ** decimalsUSDT).toNumber(),
        totalClaimedRewards: new BigNumber(trustYieldUSDT.userInfo.totalClaimedRewards).div(10 ** decimalsUSDT).toNumber(),
        unclaimedReward: new BigNumber(trustYieldUSDT.userInfo.unclaimedReward).div(10 ** decimalsUSDT).toNumber(),
        dailyRewards: totalDeposited * dailyRewardUSDT / 100
      });

      const _nextClaimDate = trustYieldUSDT.nextClaimDate;
      setNextClaimDateUSDT(_nextClaimDate);

      setIsClaimableUSDT(new Date().getTime() >= _nextClaimDate * 1000 && parseFloat(_reward) >= parseFloat(trustYieldUSDT.CLAIM_MIN_AMOUNT))
    }

  }, [trustYieldUSDT.reward, trustYieldUSDT.userInfo]);
  //

  useEffect(() => {
    if (busdContract.balance !== undefined)
      setBusdBalance(new BigNumber(busdContract.balance).div(10 ** decimals).toNumber());
  }, [busdContract?.balance]);

  useEffect(() => {
    setBusdAllowance(
      new BigNumber(busdContract.allowance).div(10 ** decimals).toNumber()
    );

  }, [busdContract?.allowance]);

  useEffect(() => {
    setDailyReward(stakingContract.dailyReward * 100 / 1000);
    setDepositMinAmount(stakingContract.DEPOSIT_MIN_AMOUNT);
    setClaimMinAmount(stakingContract.CLAIM_MIN_AMOUNT);
    setDecimals(stakingContract.decimals);
  }, [stakingContract?.dailyReward]);

  useEffect(() => {
    setTotalCurrentAssets(new BigNumber(stakingContract.totalCurrentAssets).div(10 ** decimals).toNumber());
  }, [stakingContract?.totalCurrentAssets]);

  useEffect(() => {
    const _reward = new BigNumber(stakingContract.reward).div(10 ** decimals).toNumber();
    setReward(_reward);

    if (stakingContract.userInfo) {
      const totalDeposited = new BigNumber(stakingContract.userInfo.totalDeposited).div(10 ** decimals).toNumber();

      setUserInfo({
        totalDeposited: totalDeposited,
        totalReferralEarnings: new BigNumber(stakingContract.userInfo.totalReferralEarnings).div(10 ** decimals).toNumber(),
        totalClaimedRewards: new BigNumber(stakingContract.userInfo.totalClaimedRewards).div(10 ** decimals).toNumber(),
        unclaimedReward: new BigNumber(stakingContract.userInfo.unclaimedReward).div(10 ** decimals).toNumber(),
        dailyRewards: totalDeposited * dailyReward / 100
      });

      const _nextClaimDate = stakingContract.nextClaimDate;
      setNextClaimDate(_nextClaimDate);

      setIsClaimable(_nextClaimDate !== 0 && new Date().getTime() >= _nextClaimDate * 1000 && parseFloat(_reward) >= parseFloat(stakingContract.CLAIM_MIN_AMOUNT))
    }

  }, [stakingContract.reward, stakingContract.userInfo]);

  useEffect(() => {
    if (wallet.account) {
      if (busdContract.balance !== undefined)
        setBusdBalance(new BigNumber(busdContract.balance).div(10 ** decimals).toNumber());
      if (usdcContract.balance !== undefined)
        setBalanceUSDC(new BigNumber(usdcContract.balance).div(10 ** decimalsUSDC).toNumber());
      setBalanceUSDT(new BigNumber(usdtContract.balance).div(10 ** decimalsUSDT).toNumber());
      setRefLink(`${process.env.REACT_APP_DOMAIN}?ref=${makeReferralCode(wallet.account)}`);
    } else {
      setBusdBalance(0);
      setBalanceUSDC(0);
      setBalanceUSDT(0);
      setUserInfo(null);
      setUserInfoUSDC(null);
      setUserInfoUSDT(null);
      setNextClaimDate(0);
      setNextClaimDateUSDC(0);
      setNextClaimDateUSDT(0);
      setReward(0);
      setRewardUSDC(0);
      setRewardUSDT(0);
      setIsClaimable(false);
      setIsClaimableUSDC(false);
      setIsClaimableUSDT(false);
      setRefLink(process.env.REACT_APP_DOMAIN);
    }
  }, [wallet]);

  const approveDAI = async () => {
    if (!checkWalletConnection()) return;

    setPending(true);
    setPendingAction("approve");

    try {
      await busdContract.approve();
      triggerNotification(
        "Deposit",
        `Successfully approved.`,
        "info"
      );
    } catch (e) {
      console.log("approveDAI exception: canceled", e);
      triggerNotification(
        "Deposit",
        `Operation has been cancelled. Please try again.`,
        "warning"
      );
    }

    setPending(false);
    setPendingAction(null);
  };

  const checkWalletConnection = () => {
    if (wallet.account) {
      return true;
    }
    else {
      triggerNotification(
        "Deposit",
        `Please connect your wallet.`,
        "info"
      );
    }
  }

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();

  const epsNumber = (val) => {
    const fl = Math.floor(val * 1e10) / 1e10;
    if (val - fl > 0) {
      return fl;
    }
    else {
      return val;
    }
  }

  const depositBUSD = async (refCode) => {

    if (!checkWalletConnection()) return;

    if (parseFloat(depositAmount) < parseFloat(depositMinAmount)) {
      triggerNotification(
        "Deposit",
        `Minimum deposit amount is ${depositMinAmount} DAI.`,
        "info"
      );
      return;
    }

    setPending(true);
    setPendingAction("deposit");

    try {
      const result = await stakingContract.deposit(decodeReferralCode(refCode), new BigNumber(epsNumber(depositAmount)).times(10 ** decimals));
      triggerNotification(
        "Deposit",
        `Successfully deposited.`,
        "info"
      );
    } catch (e) {
      console.log("depositBUSD exception: ", e);
      triggerNotification(
        "Deposit",
        `Operation has been cancelled. Please try again.`,
        "warning"
      );
    }

    setPending(false);
    setPendingAction(null);
  };

  const claimReward = async () => {

    if (!checkWalletConnection()) return;

    if (parseFloat(reward) < parseFloat(claimMinAmount)) {
      triggerNotification(
        "Deposit",
        `Minimum claimable amount is ${claimMinAmount} DAI.`,
        "info"
      );
      return;
    }

    setPending(true);
    setPendingAction("claim");

    try {
      await stakingContract.claim();
      triggerNotification(
        "Deposit",
        `Successfully claimed.`,
        "info"
      );
    } catch (e) {
      console.log("claim exception: ", e);
      triggerNotification(
        "Deposit",
        `Operation has been cancelled. Please try again.`,
        "warning"
      );
    }

    setPending(false);
    setPendingAction(null);
  };

  const reinvest = async () => {

    if (!checkWalletConnection()) return;

    if (parseFloat(reward) < parseFloat(claimMinAmount)) {
      triggerNotification(
        "Deposit",
        `Minimum reinvesting amount is ${claimMinAmount} DAI.`,
        "info"
      );
      return;
    }

    setPending(true);
    setPendingAction("reinvest");

    try {
      await stakingContract.reinvest();
      triggerNotification(
        "Deposit",
        `Successfully reinvested.`,
        "info"
      );
    } catch (e) {
      console.log("reinvest exception: ", e);
      triggerNotification(
        "Deposit",
        `Operation has been cancelled. Please try again.`,
        "warning"
      );
    }

    setPending(false);
    setPendingAction(null);
  };

  const withdrawBUSD = async () => {

    if (!checkWalletConnection()) return;

    setPending(true);
    setPendingAction("withdraw");

    try {
      await stakingContract.withdraw();
      triggerNotification(
        "Withraw",
        `Successfully refunded.`,
        "info"
      );
    } catch (e) {
      console.log("withdraw exception: ", e);
      triggerNotification(
        "Withraw",
        `Operation has been cancelled. Please try again.`,
        "warning"
      );
    }

    setPending(false);
    setPendingAction(null);
  };

  const rewardTimer = async () => {
    busdContract.reload();
    usdcContract.reload();
    usdtContract.reload();
    stakingContract.reload();
    trustYieldUSDC.reload();
    trustYieldUSDT.reload();
  };

  let statToggle = () => {

    if (!started || !startedBUSD) return;

    if (statm === false) {
      if (wallet.account)
        setstatm(true);
    } else {
      setstatm(false);
    }
  };

  let switchModal = () => {
    if (modalswitch === false) {
      setModalswitch(true);
    } else {
      setModalswitch(false);
    }
  };

  let toggleDeposit1 = () => {
    // return;
    if (deposit1 === false) {
      setDeposit1(true);
      setShowButtonText1("Close");

      setDeposit2(false);
      setShowButtonText2("Deposit");
      setDeposit3(false);
      setShowButtonText3("Deposit");
    } else {
      setDeposit1(false);
      setShowButtonText1("Deposit");
    }
  };

  // USDC
  const toggleDeposit2 = () => {
    // return;
    if (deposit2 === false) {
      setDeposit2(true);
      setShowButtonText2("Close");

      setDeposit1(false);
      setShowButtonText1("Deposit");
      setDeposit3(false);
      setShowButtonText3("Deposit");
    } else {
      setDeposit2(false);
      setShowButtonText2("Deposit");
    }
  };

  // USDT
  const toggleDeposit3 = () => {
    if (deposit3 === false) {
      setDeposit3(true);
      setShowButtonText3("Close");

      setDeposit1(false);
      setShowButtonText1("Deposit");
      setDeposit2(false);
      setShowButtonText2("Deposit");
    } else {
      setDeposit3(false);
      setShowButtonText3("Deposit");
    }
  };

  // USDC
  const approveUSDC = async () => {
    if (!checkWalletConnection()) return;

    setPending(true);
    setPendingAction("approve");

    try {
      await usdcContract.approve();
      triggerNotification(
        "Deposit",
        `Successfully approved.`,
        "info"
      );
    } catch (e) {
      console.log("approveUSDC exception: canceled", e);
      triggerNotification(
        "Deposit",
        `Operation has been cancelled. Please try again.`,
        "warning"
      );
    }

    setPending(false);
    setPendingAction(null);
  };

  const depositUSDC = async (refCode) => {

    if (!checkWalletConnection()) return;

    if (parseFloat(depositAmountUSDC) < parseFloat(depositMinAmountUSDC)) {
      triggerNotification(
        "Deposit",
        `Minimum deposit amount is ${depositMinAmountUSDC} USDC.`,
        "info"
      );
      return;
    }

    setPending(true);
    setPendingAction("deposit");

    try {
      const result = await trustYieldUSDC.deposit(decodeReferralCode(refCode), new BigNumber(epsNumber(depositAmountUSDC)).times(10 ** decimalsUSDC));
      triggerNotification(
        "Deposit",
        `Successfully deposited.`,
        "info"
      );
    } catch (e) {
      console.log("depositBUSD exception: ", e);
      triggerNotification(
        "Deposit",
        `Operation has been cancelled. Please try again.`,
        "warning"
      );
    }

    setPending(false);
    setPendingAction(null);
  };

  const claimRewardUSDC = async () => {

    if (!checkWalletConnection()) return;

    if (parseFloat(rewardUSDC) < parseFloat(claimMinAmountUSDC)) {
      triggerNotification(
        "Deposit",
        `Minimum claimable amount is ${claimMinAmountUSDC} USDC.`,
        "info"
      );
      return;
    }

    setPending(true);
    setPendingAction("claim");

    try {
      await trustYieldUSDC.claim();
      triggerNotification(
        "Deposit",
        `Successfully claimed.`,
        "info"
      );
    } catch (e) {
      console.log("claim exception: ", e);
      triggerNotification(
        "Deposit",
        `Operation has been cancelled. Please try again.`,
        "warning"
      );
    }

    setPending(false);
    setPendingAction(null);
  };

  const reinvestUSDC = async () => {

    if (!checkWalletConnection()) return;

    if (parseFloat(rewardUSDC) < parseFloat(claimMinAmountUSDC)) {
      triggerNotification(
        "Deposit",
        `Minimum reinvesting amount is ${claimMinAmountUSDC} USDC.`,
        "info"
      );
      return;
    }

    setPending(true);
    setPendingAction("reinvest");

    try {
      await trustYieldUSDC.reinvest();
      triggerNotification(
        "Deposit",
        `Successfully reinvested.`,
        "info"
      );
    } catch (e) {
      console.log("reinvest exception: ", e);
      triggerNotification(
        "Deposit",
        `Operation has been cancelled. Please try again.`,
        "warning"
      );
    }

    setPending(false);
    setPendingAction(null);
  };

  const withdrawUSDC = async () => {

    if (!checkWalletConnection()) return;

    setPending(true);
    setPendingAction("withdraw");

    try {
      await trustYieldUSDC.withdraw();
      triggerNotification(
        "Withraw",
        `Successfully refunded.`,
        "info"
      );
    } catch (e) {
      console.log("withdraw exception: ", e);
      triggerNotification(
        "Withraw",
        `Operation has been cancelled. Please try again.`,
        "warning"
      );
    }

    setPending(false);
    setPendingAction(null);
  };

  let statToggleUSDC = () => {

    if (!started || !startedUSDC) return;

    if (statusDialogUSDC === false) {
      if (wallet.account)
        setStatusDialogUSDC(true);
    } else {
      setStatusDialogUSDC(false);
    }
  };

  // USDT
  const approveUSDT = async () => {
    if (!checkWalletConnection()) return;

    setPending(true);
    setPendingAction("approve");

    try {
      await usdtContract.approve();
      triggerNotification(
        "Deposit",
        `Successfully approved.`,
        "info"
      );
    } catch (e) {
      console.log("approveUSDT exception: canceled", e);
      triggerNotification(
        "Deposit",
        `Operation has been cancelled. Please try again.`,
        "warning"
      );
    }

    setPending(false);
    setPendingAction(null);
  };

  const depositUSDT = async (refCode) => {

    if (!checkWalletConnection()) return;

    if (parseFloat(depositAmountUSDT) < parseFloat(depositMinAmountUSDT)) {
      triggerNotification(
        "Deposit",
        `Minimum deposit amount is ${depositMinAmountUSDT} USDT.`,
        "info"
      );
      return;
    }

    setPending(true);
    setPendingAction("deposit");

    try {
      const result = await trustYieldUSDT.deposit(decodeReferralCode(refCode), new BigNumber(epsNumber(depositAmountUSDT)).times(10 ** decimalsUSDT));
      triggerNotification(
        "Deposit",
        `Successfully deposited.`,
        "info"
      );
    } catch (e) {
      console.log("depositBUSD exception: ", e);
      triggerNotification(
        "Deposit",
        `Operation has been cancelled. Please try again.`,
        "warning"
      );
    }

    setPending(false);
    setPendingAction(null);
  };

  const claimRewardUSDT = async () => {

    if (!checkWalletConnection()) return;

    if (parseFloat(rewardUSDT) < parseFloat(claimMinAmountUSDT)) {
      triggerNotification(
        "Deposit",
        `Minimum claimable amount is ${claimMinAmountUSDT} USDT.`,
        "info"
      );
      return;
    }

    setPending(true);
    setPendingAction("claim");

    try {
      await trustYieldUSDT.claim(new BigNumber(epsNumber(rewardUSDT)).times(10 ** decimalsUSDT));
      triggerNotification(
        "Deposit",
        `Successfully claimed.`,
        "info"
      );
    } catch (e) {
      console.log("claim exception: ", e);
      triggerNotification(
        "Deposit",
        `Operation has been cancelled. Please try again.`,
        "warning"
      );
    }

    setPending(false);
    setPendingAction(null);
  };

  const reinvestUSDT = async () => {

    if (!checkWalletConnection()) return;

    if (parseFloat(rewardUSDT) < parseFloat(claimMinAmountUSDT)) {
      triggerNotification(
        "Deposit",
        `Minimum reinvesting amount is ${claimMinAmountUSDT} USDT.`,
        "info"
      );
      return;
    }

    setPending(true);
    setPendingAction("reinvest");

    try {
      await trustYieldUSDT.reinvest(new BigNumber(epsNumber(rewardUSDT)).times(10 ** decimalsUSDT));
      triggerNotification(
        "Deposit",
        `Successfully reinvested.`,
        "info"
      );
    } catch (e) {
      console.log("reinvest exception: ", e);
      triggerNotification(
        "Deposit",
        `Operation has been cancelled. Please try again.`,
        "warning"
      );
    }

    setPending(false);
    setPendingAction(null);
  };

  const withdrawUSDT = async () => {

    if (!checkWalletConnection()) return;

    setPending(true);
    setPendingAction("withdraw");

    try {
      await trustYieldUSDT.withdraw();
      triggerNotification(
        "Withraw",
        `Successfully refunded.`,
        "info"
      );
    } catch (e) {
      console.log("withdraw exception: ", e);
      triggerNotification(
        "Withraw",
        `Operation has been cancelled. Please try again.`,
        "warning"
      );
    }

    setPending(false);
    setPendingAction(null);
  };

  let statToggleUSDT = () => {

    if (!started) return;

    if (statusDialogUSDT === false) {
      if (wallet.account)
        setStatusDialogUSDT(true);
    } else {
      setStatusDialogUSDT(false);
    }
  };

  const isActive = () => {
    return new Date().getTime() >= activeTime * 1000
  }

  return (
    <>
      <ReactInterval
        {...{ timeout, enabled }}
        callback={() => rewardTimer()}
      />
      <div className="pool-mn">
        <ReactTooltip anchorId="my-element" />
        <ReactTooltip anchorId="my-element2" />
        <ReactTooltip anchorId="my-element3" />
        <ReactTooltip anchorId="my-element4" />
        <ReactTooltip anchorId="my-element5" />
        <ReactTooltip anchorId="my-element6" />

        <ReactTooltip anchorId="tooltip-busd-1" />
        <ReactTooltip anchorId="tooltip-busd-2" />
        <ReactTooltip anchorId="tooltip-busd-3" />
        <ReactTooltip anchorId="tooltip-busd-4" />
        <ReactTooltip anchorId="tooltip-busd-5" />
        <ReactTooltip anchorId="tooltip-busd-6" />

        {/* USDC */}
        <ReactTooltip anchorId="tooltip-usdc-1" />
        <ReactTooltip anchorId="tooltip-usdc-2" />
        <ReactTooltip anchorId="tooltip-usdc-3" />
        <ReactTooltip anchorId="tooltip-usdc-4" />
        <ReactTooltip anchorId="tooltip-usdc-5" />
        <ReactTooltip anchorId="tooltip-usdc-6" />

        {/* USDT */}
        <ReactTooltip anchorId="tooltip-usdt-1" />
        <ReactTooltip anchorId="tooltip-usdt-2" />
        <ReactTooltip anchorId="tooltip-usdt-3" />
        <ReactTooltip anchorId="tooltip-usdt-4" />
        <ReactTooltip anchorId="tooltip-usdt-5" />
        <ReactTooltip anchorId="tooltip-usdt-6" />

        <ReactTooltip anchorId="tooltip-usdt-mobile-1" />
        <ReactTooltip anchorId="tooltip-usdt-mobile-2" />
        <ReactTooltip anchorId="tooltip-usdt-mobile-3" />
        <ReactTooltip anchorId="tooltip-usdt-mobile-4" />
        <ReactTooltip anchorId="tooltip-usdt-mobile-5" />
        <ReactTooltip anchorId="tooltip-usdt-mobile-6" />

        <div className="row m-0 align-items-center justify-content-between gap-2 w-100 pb-2">
          <h6 className="top-pools-title gradient-title">Top Pools</h6>
        </div>

        <div>

          <div className="row m-0 gap-4 toppool-allwrapper">
            {/* USDT  */}
            <div
              className={showButtonText3 === "Close" ? "poolscardwrapper cursor-pointer position-relative false selected-border" : "poolscardwrapper cursor-pointer position-relative false"}
              onClick={toggleDeposit3}
            >
              <img
                src={NewRibbonIcon}
                className="new-pool"
                alt="new pool"
              />
              <div
                className="purplediv"
                style={{ background: "rgb(136, 144, 196)", top: 12 }}
              />
              <div className="d-flex flex-column gap-0">
                <div className="d-flex m-0 justify-content between gap-2 align-items-center justify-content-between title-apr-wrapper">
                  <div className="d-flex align-items-center">
                    <h6 className="token-name d-flex align-items-center gap-2">
                      <img
                        src={USDTIcon}
                        alt=""
                        className="tokenlogo"
                        width={32}
                        height={32}
                      />{" "}
                      USDT
                    </h6>
                  </div>
                  <div className="d-flex align-items-baseline gap-1">
                    <h6 className="apr-amount">{dailyRewardUSDT}%</h6>
                    <h6 className="apr-title">Daily ROI</h6>
                  </div>
                </div>
                <div className="d-flex m-0 justify-content between gap-2 align-items-center justify-content-between bottomwrapper } ">
                  <div className="d-flex flex-column">
                    <h6 className="tvl-text">Total Current Assets</h6>
                    <h6 className="tvl-amount">${totalCurrentAssetsUSDT.toFixed(0)}</h6>
                  </div>
                  <div className="d-flex flex-column align-items-end">
                    <h6 className="tvl-text">Lock Time</h6>
                    <h6 className="locktime-amount">30 days</h6>
                  </div>
                </div>
                <div className="details-wrapper">
                  <h6
                    className={
                      deposit3
                        ? "details-text gap-1 d-flex align-items-center"
                        : "details-text gap-1 clr d-flex align-items-center"
                    }
                  >
                    {showButtonText3}
                    <img
                      className={deposit3 ? "rotate-it" : ""}
                      src="https://app.dypius.com/static/media/greenarrow.2715b29d.svg"
                      alt=""
                    />
                  </h6>
                </div>
              </div>
            </div>


            {/* USDT deposit for mobile */}
            <div className={deposit3 ? "container-lg p-0 mobile-show desktop-hide" : "hide-it"}>
              <div className="allwrapper my-4 selected-border">
                <div className="leftside2 w-100">
                  <div className="activewrapper">
                    <div className="d-flex flex-column flex-lg-row w-100 align-items-start align-items-lg-center justify-content-between gap-3 gap-lg-5 pool-details-header">
                      <h6 className="activetxt">
                        {/* <img
                          src="https://app.dypius.com/static/media/ellipse.01dab43d.svg"
                          alt=""
                          className="position-relative"
                          style={{ top: "-1px" }}
                        /> */}
                        {started ? <EllipseSvg className="position-relative" style={{ top: "-1px" }} /> : <EllipseSvgGreen className="position-relative" style={{ top: "-1px" }} />}
                        {started ? "Active" : "Ready"}
                        &nbsp;&nbsp;
                        {!isActive() && (
                          <Countdown
                            date={activeTime * 1000}
                            renderer={CountdownRenderer}
                          />
                        )}
                      </h6>
                      <div className="d-flex flex-row-reverse flex-lg-row align-items-center justify-content-between earnrewards-container">
                        <div className="d-flex flex-column flex-lg-row align-items-end align-items-lg-center gap-3 gap-lg-5">
                          <div className="d-flex  align-items-center justify-content-between gap-2">
                            <h6 className="earnrewards-text">Development fee:</h6>
                            <h6 className="earnrewards-token d-flex align-items-center gap-1">
                              3%
                              <img
                                id="tooltip-usdt-mobile-1"
                                data-tooltip-content=" A 3% fee is applicable on all deposits, including re-deposits, to maintain, improve, and extend the project."
                                src="https://app.dypius.com/static/media/more-info.95629bb7.svg"
                                alt=""
                              />
                            </h6>
                          </div>
                          <div className="d-flex align-items-center justify-content-between gap-2">
                            <h6 className="earnrewards-text">Daily ROI:</h6>
                            <h6 className="earnrewards-token d-flex align-items-center gap-1">
                              {dailyRewardUSDT}%
                              <img
                                id="tooltip-usdt-mobile-2"
                                data-tooltip-content="The daily ROI remains stable as long as the user maintains regular activity."
                                src="https://app.dypius.com/static/media/more-info.95629bb7.svg"
                                alt=""
                              />
                            </h6>
                          </div>
                          <div className="d-flex align-items-center justify-content-between gap-2">
                            <h6 className="earnrewards-text">Lock time:</h6>
                            <h6 className="earnrewards-token d-flex align-items-center gap-1">
                              0 Days
                              <img
                                id="tooltip-usdt-mobile-3"
                                data-tooltip-content=" The amount of time your deposited assets will be locked before you
  can request a withdrawal."
                                src="https://app.dypius.com/static/media/more-info.95629bb7.svg"
                                alt=""
                              />
                            </h6>
                          </div>
                        </div>
                        <div className="d-flex flex-column flex-lg-row align-items-start align-items-lg-center justify-content-between gap-3">
                          {started && (
                            <a
                              href={`https://arbiscan.io/address/${YC_CONTRACT()}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <h6 className="bottomitems">
                                <img
                                  src="https://app.dypius.com/static/media/arrow-up.987a80d4.svg"
                                  alt=""
                                />
                                Verified Contract
                              </h6>
                            </a>
                          )}
                          <a
                            // href="https://app.uniswap.org/#/swap?outputCurrency=0xdac17f958d2ee523a2206206994597c13d831ec7"
                            href="https://www.sushi.com/swap?fromChainId=42161&fromCurrency=NATIVE&toChainId=42161&toCurrency=0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <h6 className="bottomitems">
                              <img
                                src="https://app.dypius.com/static/media/arrow-up.987a80d4.svg"
                                alt=""
                              />
                              Get USDT
                            </h6>
                          </a>
                          <div onClick={statToggleUSDT}>
                            <h6 className="bottomitems">
                              <img
                                src="https://app.dypius.com/static/media/purpleStat.87015297.svg"
                                alt=""
                              />
                              Stats
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pools-details-wrapper d-flex m-0 container-lg border-0">
                  <div className="row gap-4 gap-lg-0 w-100 justify-content-between">
                    <div className="firstblockwrapper col-12 col-md-6 col-lg-2">
                      <div className="d-flex flex-row flex-lg-column align-items-center align-items-lg-start justify-content-between gap-4">
                        <h6 className="start-title">Start Staking</h6>

                        <ConnectedWallet />
                      </div>
                    </div>
                    <div className="otherside-border col-12 col-md-12 col-lg-4 pool-deposit-stake">
                      <div className="d-flex justify-content-between align-items-center gap-2">
                        <div className="d-flex justify-content-center align-items-center gap-3">
                          <h6 className="deposit-txt">Deposit</h6>
                          <h6 className="mybalance-text">
                            Balance: <b>{(balanceUSDT).toFixed(2)} USDT</b>
                          </h6>
                        </div>
                        <div className="d-flex flex-row">
                          <h6 className="mybalance-text" style={{ marginRight: "10px" }}>
                            Staked: <b>{userInfoUSDT && userInfoUSDT.totalDeposited ? userInfoUSDT.totalDeposited.toFixed(2) : 0} USDT</b>
                          </h6>
                          <img
                            id="tooltip-usdt-mobile-4"
                            data-tooltip-content=" You can earn stable income by depositing a minimum of 20 USDT and allowing our YieldCat to do the rest for you."
                            src="https://app.dypius.com/static/media/more-info.95629bb7.svg"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="d-flex flex-column gap-2 justify-content-between">
                        <div className="d-flex spw align-items-center justify-content-between gap-2">
                          <div className="input-container px-0">
                            <input
                              type="number"
                              autoComplete="off"
                              placeholder="Amount"
                              className="text-input"
                              name="amount_deposit"
                              id="amount_deposit"
                              style={{ width: "100%" }}
                              value={depositAmountUSDT}
                              onChange={e => setDepositAmountUSDT(e.target.value)}
                            />
                            {/* <label className="label">Amount</label> */}
                          </div>
                          <button className="btn maxbtn" onClick={() => {
                            setDepositAmountUSDT(balanceUSDT)
                          }}>Max</button>
                          {allowanceUSDT === 0 || allowanceUSDT < depositAmountUSDT ? (
                            <CustomButton
                              className="btn filledbtn null d-flex justify-content-center align-items-center gap-2"
                              onClick={approveUSDT}
                              // style={{ marginLeft: "10px" }}
                              label="Approve"
                              isDisabled={pending || !started}
                              isLoading={pendingAction === "approve"}
                            />
                          ) : (
                            <CustomButton
                              className="btn filledbtn null d-flex justify-content-center align-items-center gap-2"
                              onClick={() => depositUSDT(query.get("ref"))}
                              // style={{ marginLeft: "10px" }}
                              label="Deposit"
                              isDisabled={pending || !started || !isActive()}
                              isLoading={pendingAction === "deposit"}
                            />
                          )}

                        </div>
                      </div>
                    </div>
                    <div className="otherside-border col-12 col-md-12 col-lg-4 false pool-deposit-rewards">
                      <div className="d-flex justify-content-between gap-2">
                        <h6 className="withdraw-txt">Rewards</h6>
                        <h6 className="withdraw-littletxt d-flex align-items-center gap-2">
                          Rewards are displayed in real-time
                          <img
                            id="tooltip-usdt-mobile-5"
                            data-tooltip-content="  You can claim rewards every 8 hours, and the minimum amount you can claim or re-deposit is 10 USDT. There is no specific timeframe for re-depositing."
                            src="https://app.dypius.com/static/media/more-info.95629bb7.svg"
                            alt=""
                          />
                        </h6>
                      </div>
                      <div className="form-row flex-column flex-lg-row d-flex gap-2 align-item-start align-items-lg-center justify-content-between">
                        <div className="d-flex flex-column">
                          <span
                            style={{
                              fontWeight: 600,
                              fontSize: "13px",
                              lineHeight: "18px",
                              color: "rgb(192, 201, 255)",
                            }}
                          >
                            USDT
                          </span>
                          <div className="d-flex flex-row">
                            <span
                              style={{
                                fontWeight: 600,
                                fontSize: "16px",
                              }}
                            >
                              <p className="" style={{ marginRight: "10px" }}>{rewardUSDT.toFixed(2)}</p>
                            </span>

                            {nextClaimDateUSDT !== 0 && new Date().getTime() < nextClaimDateUSDT * 1000 && (
                              <Countdown
                                date={nextClaimDateUSDT * 1000}
                                renderer={CountdownRenderer}
                              />

                            )}
                            {isClaimableUSDT && (
                              <span style={{ color: "greenyellow", fontSize: "14px" }}>claimable</span>
                            )}
                          </div>
                        </div>
                        <div className="claim-reinvest-container d-flex justify-content-between align-items-center gap-3">

                          <CustomButton
                            className="btn filledbtn d-flex justify-content-center align-items-center gap-2"
                            onClick={claimRewardUSDT}
                            label="Claim"
                            isDisabled={pending || !isClaimableUSDT || !started}
                            isLoading={pendingAction === "claim"}
                          />

                          <CustomButton
                            className="btn filledbtn d-flex justify-content-center align-items-center gap-2"
                            onClick={reinvestUSDT}
                            label="Reinvest"
                            isDisabled={pending || !started}
                            isLoading={pendingAction === "reinvest"}
                          />

                          {/* <button
              disabled
              className="btn outline-btn disabled-btn d-flex justify-content-center align-items-center gap-2"
              style={{ height: "fit-content" }}
            >
              Reinvest
            </button> */}
                        </div>
                      </div>
                    </div>
                    <div className="otherside-border col-12 col-md-12 col-lg-2 false">
                      <h6 className="deposit-txt d-flex align-items-center gap-2 justify-content-between">
                        WITHDRAW
                        <img
                          id="tooltip-usdt-mobile-6"
                          data-tooltip-content="   You can withdraw the full deposited amount at any time. Withdrawing before 30 days incurs a penalty. Once you withdraw the full amount, then your account will be initialized."
                          src="https://app.dypius.com/static/media/more-info.95629bb7.svg"
                          alt=""
                        />
                      </h6>
                      <CustomButton
                        className="btn filledbtn withdrawbtn d-flex justify-content-center align-items-center gap-2"
                        onClick={withdrawUSDT}
                        label="Refund"
                        isDisabled={pending || !started || !(userInfoUSDT && userInfoUSDT.totalDeposited > 0)}
                        isLoading={pendingAction === "withdraw"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* USDC  */}
            <div
              className={showButtonText2 === "Close" ? "poolscardwrapper cursor-pointer position-relative false selected-border" : "poolscardwrapper cursor-pointer position-relative false"}
              onClick={toggleDeposit2}
            >
              <img
                src={SoonRibbonIcon}
                className="new-pool"
                alt="new pool"
              />
              <div
                className="purplediv"
                style={{ background: "rgb(136, 144, 196)", top: 12 }}
              />
              <div className="d-flex flex-column gap-0">
                <div className="d-flex m-0 justify-content between gap-2 align-items-center justify-content-between title-apr-wrapper">
                  <div className="d-flex align-items-center">
                    <h6 className="token-name d-flex align-items-center gap-2">
                      <img
                        src={USDCIcon}
                        alt=""
                        className="tokenlogo"
                        width={32}
                        height={32}
                      />{" "}
                      USDC
                    </h6>
                  </div>
                  <div className="d-flex align-items-baseline gap-1">
                    <h6 className="apr-amount">{dailyRewardUSDC}%</h6>
                    <h6 className="apr-title">Daily ROI</h6>
                  </div>
                </div>
                <div className="d-flex m-0 justify-content between gap-2 align-items-center justify-content-between bottomwrapper } ">
                  <div className="d-flex flex-column">
                    <h6 className="tvl-text">Total Current Assets</h6>
                    <h6 className="tvl-amount">${totalCurrentAssetsUSDC.toFixed(0)}</h6>
                  </div>
                  <div className="d-flex flex-column align-items-end">
                    <h6 className="tvl-text">Lock Time</h6>
                    <h6 className="locktime-amount">30 days</h6>
                  </div>
                </div>
                <div className="details-wrapper">
                  <h6
                    className={
                      deposit2
                        ? "details-text gap-1 d-flex align-items-center"
                        : "details-text gap-1 clr d-flex align-items-center"
                    }
                  >
                    {showButtonText2}
                    <img
                      className={deposit2 ? "rotate-it" : ""}
                      src="https://app.dypius.com/static/media/greenarrow.2715b29d.svg"
                      alt=""
                    />
                  </h6>
                </div>
              </div>
            </div>
          </div>

          {/* USDT  detail */}
          <div className={deposit3 ? "container-lg p-0 mobile-hide" : "hide-it"}>
            <div className="allwrapper my-4 selected-border">
              <div className="leftside2 w-100">
                <div className="activewrapper">
                  <div className="d-flex flex-column flex-lg-row w-100 align-items-start align-items-lg-center justify-content-between gap-3 gap-lg-5 pool-details-header">
                    <h6 className="activetxt">
                      {/* <img
                        src="https://app.dypius.com/static/media/ellipse.01dab43d.svg"
                        alt=""
                        className="position-relative"
                        style={{ top: "-1px" }}
                      /> */}
                      {started ? <EllipseSvg className="position-relative" style={{ top: "-1px" }} /> : <EllipseSvgGreen className="position-relative" style={{ top: "-1px" }} />}
                      {started ? "Active" : "Ready"}
                      &nbsp;&nbsp;
                      {!isActive() && (
                        <Countdown
                          date={activeTime * 1000}
                          renderer={CountdownRenderer}
                        />
                      )}

                    </h6>
                    <div className="d-flex flex-row-reverse flex-lg-row align-items-center justify-content-between earnrewards-container">
                      <div className="d-flex flex-column flex-lg-row align-items-end align-items-lg-center gap-3 gap-lg-5">
                        <div className="d-flex  align-items-center justify-content-between gap-2">
                          <h6 className="earnrewards-text">Development fee:</h6>
                          <h6 className="earnrewards-token d-flex align-items-center gap-1">
                            3%
                            <img
                              id="tooltip-usdt-1"
                              data-tooltip-content=" A 3% fee is applicable on all deposits, including re-deposits, to maintain, improve, and extend the project."
                              src="https://app.dypius.com/static/media/more-info.95629bb7.svg"
                              alt=""
                            />
                          </h6>
                        </div>
                        <div className="d-flex align-items-center justify-content-between gap-2">
                          <h6 className="earnrewards-text">Daily ROI:</h6>
                          <h6 className="earnrewards-token d-flex align-items-center gap-1">
                            {dailyRewardUSDT}%
                            <img
                              id="tooltip-usdt-2"
                              data-tooltip-content="The daily ROI remains stable as long as the user maintains regular activity."
                              src="https://app.dypius.com/static/media/more-info.95629bb7.svg"
                              alt=""
                            />
                          </h6>
                        </div>
                        <div className="d-flex align-items-center justify-content-between gap-2">
                          <h6 className="earnrewards-text">Lock time:</h6>
                          <h6 className="earnrewards-token d-flex align-items-center gap-1">
                            0 Days
                            <img
                              id="tooltip-usdt-3"
                              data-tooltip-content=" There is no locked period for withdrawing the full deposited amount, but withdrawing before a certain period incurs a penalty."
                              src="https://app.dypius.com/static/media/more-info.95629bb7.svg"
                              alt=""
                            />
                          </h6>
                        </div>
                      </div>
                      <div className="d-flex flex-column flex-lg-row align-items-start align-items-lg-center justify-content-between gap-3">
                        {started && (
                          <a
                            href={`https://arbiscan.io/address/${YC_CONTRACT()}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <h6 className="bottomitems">
                              <img
                                src="https://app.dypius.com/static/media/arrow-up.987a80d4.svg"
                                alt=""
                              />
                              Verified Contract
                            </h6>
                          </a>
                        )}
                        <a
                          // href="https://app.uniswap.org/#/swap?outputCurrency=0xdac17f958d2ee523a2206206994597c13d831ec7"
                          href="https://www.sushi.com/swap?fromChainId=42161&fromCurrency=NATIVE&toChainId=42161&toCurrency=0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9"

                          target="_blank"
                          rel="noreferrer"
                        >
                          <h6 className="bottomitems">
                            <img
                              src="https://app.dypius.com/static/media/arrow-up.987a80d4.svg"
                              alt=""
                            />
                            Get USDT
                          </h6>
                        </a>
                        <div onClick={statToggleUSDT}>
                          <h6 className="bottomitems">
                            <img
                              src="https://app.dypius.com/static/media/purpleStat.87015297.svg"
                              alt=""
                            />
                            Stats
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pools-details-wrapper d-flex m-0 container-lg border-0">
                <div className="row gap-4 gap-lg-0 w-100 justify-content-between">
                  <div className="firstblockwrapper col-12 col-md-6 col-lg-2">
                    <div className="d-flex flex-row flex-lg-column align-items-center align-items-lg-start justify-content-between gap-4">
                      <h6 className="start-title">Start Staking</h6>

                      <ConnectedWallet />
                    </div>
                  </div>
                  <div className="otherside-border col-12 col-md-12 col-lg-4 pool-deposit-stake">
                    <div className="d-flex justify-content-between align-items-center gap-2">
                      <div className="d-flex justify-content-center align-items-center gap-3">
                        <h6 className="deposit-txt">Deposit</h6>
                        <h6 className="mybalance-text">
                          Balance: <b>{(balanceUSDT).toFixed(2)} USDT</b>
                        </h6>
                      </div>
                      <div className="d-flex flex-row">
                        <h6 className="mybalance-text" style={{ marginRight: "10px" }}>
                          Staked: <b>{userInfoUSDT && userInfoUSDT.totalDeposited ? userInfoUSDT.totalDeposited.toFixed(2) : 0} USDT</b>
                        </h6>
                        <img
                          id="tooltip-usdt-4"
                          data-tooltip-content=" You can earn stable income by depositing a minimum of 20 USDT and allowing our YieldCat to do the rest for you."
                          src="https://app.dypius.com/static/media/more-info.95629bb7.svg"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="d-flex flex-column gap-2 justify-content-between">
                      <div className="d-flex spw align-items-center justify-content-between gap-2">
                        <div className="input-container px-0">
                          <input
                            type="number"
                            autoComplete="off"
                            placeholder="Amount"
                            className="text-input"
                            name="amount_deposit"
                            id="amount_deposit"
                            style={{ width: "100%" }}
                            value={depositAmountUSDT}
                            onChange={e => setDepositAmountUSDT(e.target.value)}
                          />
                          {/* <label className="label">Amount</label> */}
                        </div>
                        <button className="btn maxbtn" onClick={() => {
                          setDepositAmountUSDT(balanceUSDT)
                        }}>Max</button>
                        {allowanceUSDT === 0 || allowanceUSDT < depositAmountUSDT ? (
                          <CustomButton
                            className="btn filledbtn null d-flex justify-content-center align-items-center gap-2"
                            onClick={approveUSDT}
                            // style={{ marginLeft: "10px" }}
                            label="Approve"
                            isDisabled={pending || !started}
                            isLoading={pendingAction === "approve"}
                          />
                        ) : (
                          <CustomButton
                            className="btn filledbtn null d-flex justify-content-center align-items-center gap-2"
                            onClick={() => depositUSDT(query.get("ref"))}
                            // style={{ marginLeft: "10px" }}
                            label="Deposit"
                            isDisabled={pending || !started || !isActive()}
                            isLoading={pendingAction === "deposit"}

                          />
                        )}

                      </div>
                    </div>
                  </div>
                  <div className="otherside-border col-12 col-md-12 col-lg-4 false pool-deposit-rewards">
                    <div className="d-flex justify-content-between gap-2">
                      <h6 className="withdraw-txt">Rewards</h6>
                      <h6 className="withdraw-littletxt d-flex align-items-center gap-2">
                        Rewards are displayed in real-time
                        <img
                          id="tooltip-usdt-5"
                          data-tooltip-content="  You can claim rewards every 8 hours, and the minimum amount you can claim or re-deposit is 10 USDT. There is no specific timeframe for re-depositing."
                          src="https://app.dypius.com/static/media/more-info.95629bb7.svg"
                          alt=""
                        />
                      </h6>
                    </div>
                    <div className="form-row flex-column flex-lg-row d-flex gap-2 align-item-start align-items-lg-center justify-content-between">
                      <div className="d-flex flex-column">
                        <span
                          style={{
                            fontWeight: 600,
                            fontSize: "13px",
                            lineHeight: "18px",
                            color: "rgb(192, 201, 255)",
                          }}
                        >
                          USDT
                        </span>
                        <div className="d-flex flex-row">
                          <span
                            style={{
                              fontWeight: 600,
                              fontSize: "16px",
                            }}
                          >
                            <p className="" style={{ marginRight: "10px" }}>{rewardUSDT.toFixed(2)}</p>
                          </span>

                          {nextClaimDateUSDT !== 0 && new Date().getTime() < nextClaimDateUSDT * 1000 && (
                            <Countdown
                              date={nextClaimDateUSDT * 1000}
                              renderer={CountdownRenderer}
                            />

                          )}
                          {isClaimableUSDT && (
                            <span style={{ color: "greenyellow", fontSize: "14px" }}>claimable</span>
                          )}
                        </div>
                      </div>
                      <div className="claim-reinvest-container d-flex justify-content-between align-items-center gap-3">

                        <CustomButton
                          className="btn filledbtn d-flex justify-content-center align-items-center gap-2"
                          onClick={claimRewardUSDT}
                          label="Claim"
                          isDisabled={pending || !isClaimableUSDT || !started}
                          isLoading={pendingAction === "claim"}
                        />

                        <CustomButton
                          className="btn filledbtn d-flex justify-content-center align-items-center gap-2"
                          onClick={reinvestUSDT}
                          label="Reinvest"
                          isDisabled={pending || !started}
                          isLoading={pendingAction === "reinvest"}
                        />

                        {/* <button
              disabled
              className="btn outline-btn disabled-btn d-flex justify-content-center align-items-center gap-2"
              style={{ height: "fit-content" }}
            >
              Reinvest
            </button> */}
                      </div>
                    </div>
                  </div>
                  <div className="otherside-border col-12 col-md-12 col-lg-2 false">
                    <h6 className="deposit-txt d-flex align-items-center gap-2 justify-content-between">
                      WITHDRAW
                      <img
                        id="tooltip-usdt-6"
                        data-tooltip-content="   You can withdraw the full deposited amount at any time. Withdrawing before 30 days incurs a penalty. Once you withdraw the full amount, then your account will be initialized."
                        src="https://app.dypius.com/static/media/more-info.95629bb7.svg"
                        alt=""
                      />
                    </h6>

                    <CustomButton
                      className="btn filledbtn withdrawbtn d-flex justify-content-center align-items-center gap-2"
                      onClick={withdrawUSDT}
                      label="Refund"
                      isDisabled={pending || !started || !(userInfoUSDT && userInfoUSDT.totalDeposited > 0)}
                      isLoading={pendingAction === "withdraw"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* USDC detail */}
          <div className={deposit2 ? "container-lg p-0" : "hide-it"}>
            <div className="allwrapper mt-4 selected-border">
              <div className="leftside2 w-100">
                <div className="activewrapper">
                  <div className="d-flex flex-column flex-lg-row w-100 align-items-start align-items-lg-center justify-content-between gap-3 gap-lg-5 pool-details-header">
                    <h6 className="activetxt">
                      {startedUSDC ? <EllipseSvg className="position-relative" style={{ top: "-1px" }} /> : <EllipseSvgGreen className="position-relative" style={{ top: "-1px" }} />}
                      {startedUSDC ? "Active" : "Ready"}
                    </h6>
                    <div className="d-flex flex-row-reverse flex-lg-row align-items-center justify-content-between earnrewards-container">
                      <div className="d-flex flex-column flex-lg-row align-items-end align-items-lg-center gap-3 gap-lg-5">
                        <div className="d-flex  align-items-center justify-content-between gap-2">
                          <h6 className="earnrewards-text">Development fee:</h6>
                          <h6 className="earnrewards-token d-flex align-items-center gap-1">
                            3%
                            <img
                              id="tooltip-usdc-1"
                              data-tooltip-content=" A 3% fee is applicable on all deposits, including re-deposits, to maintain, improve, and extend the project."
                              src="https://app.dypius.com/static/media/more-info.95629bb7.svg"
                              alt=""
                            />
                          </h6>
                        </div>
                        <div className="d-flex align-items-center justify-content-between gap-2">
                          <h6 className="earnrewards-text">Daily ROI:</h6>
                          <h6 className="earnrewards-token d-flex align-items-center gap-1">
                            {dailyRewardUSDC}%
                            <img
                              id="tooltip-usdc-2"
                              data-tooltip-content="The daily ROI remains stable as long as the user maintains regular activity."
                              src="https://app.dypius.com/static/media/more-info.95629bb7.svg"
                              alt=""
                            />
                          </h6>
                        </div>
                        <div className="d-flex align-items-center justify-content-between gap-2">
                          <h6 className="earnrewards-text">Lock time:</h6>
                          <h6 className="earnrewards-token d-flex align-items-center gap-1">
                            0 Days
                            <img
                              id="tooltip-usdc-3"
                              data-tooltip-content=" There is no locked period for withdrawing the full deposited amount, but withdrawing before a certain period incurs a penalty."
                              src="https://app.dypius.com/static/media/more-info.95629bb7.svg"
                              alt=""
                            />
                          </h6>
                        </div>
                      </div>
                      <div className="d-flex flex-column flex-lg-row align-items-start align-items-lg-center justify-content-between gap-3">
                        {startedUSDC && (<a
                          href={`https://arbiscan.io/address/${YC_CONTRACT()}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <h6 className="bottomitems">
                            <img
                              src="https://app.dypius.com/static/media/arrow-up.987a80d4.svg"
                              alt=""
                            />
                            Verified Contract
                          </h6>
                        </a>)}
                        {/* <a
                          href="https://app.uniswap.org/#/swap?outputCurrency=0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <h6 className="bottomitems">
                            <img
                              src="https://app.dypius.com/static/media/arrow-up.987a80d4.svg"
                              alt=""
                            />
                            Get USDC
                          </h6>
                        </a> */}
                        <div onClick={statToggleUSDC}>
                          <h6 className="bottomitems">
                            <img
                              src="https://app.dypius.com/static/media/purpleStat.87015297.svg"
                              alt=""
                            />
                            Stats
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pools-details-wrapper d-flex m-0 container-lg border-0">
                <div className="row gap-4 gap-lg-0 w-100 justify-content-between">
                  <div className="firstblockwrapper col-12 col-md-6 col-lg-2">
                    <div className="d-flex flex-row flex-lg-column align-items-center align-items-lg-start justify-content-between gap-4">
                      <h6 className="start-title">Start Staking</h6>

                      <ConnectedWallet />
                    </div>
                  </div>
                  <div className="otherside-border col-12 col-md-12 col-lg-4 pool-deposit-stake">
                    <div className="d-flex justify-content-between align-items-center gap-2">
                      <div className="d-flex justify-content-center align-items-center gap-3">
                        <h6 className="deposit-txt">Deposit</h6>
                        <h6 className="mybalance-text">
                          Balance: <b>{(balanceUSDC).toFixed(2)} USDC</b>
                        </h6>
                      </div>
                      <div className="d-flex flex-row">
                        <h6 className="mybalance-text" style={{ marginRight: "10px" }}>
                          Staked: <b>{userInfoUSDC && userInfoUSDC.totalDeposited ? userInfoUSDC.totalDeposited.toFixed(2) : 0} USDC</b>
                        </h6>
                        <img
                          id="tooltip-usdc-4"
                          data-tooltip-content=" You can earn stable income by depositing a minimum of 20 USDC and allowing our YieldCat to do the rest for you."
                          src="https://app.dypius.com/static/media/more-info.95629bb7.svg"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="d-flex flex-column gap-2 justify-content-between">
                      <div className="d-flex spw align-items-center justify-content-between gap-2">
                        <div className="input-container px-0">
                          <input
                            type="number"
                            autoComplete="off"
                            placeholder="Amount"
                            className="text-input"
                            name="amount_deposit"
                            id="amount_deposit"
                            style={{ width: "100%" }}
                            value={depositAmountUSDC}
                            onChange={e => setDepositAmountUSDC(e.target.value)}
                          />
                          {/* <label className="label">Amount</label> */}
                        </div>
                        <button className="btn maxbtn" onClick={() => {
                          setDepositAmountUSDC(balanceUSDC)
                        }}>Max</button>
                        {allowanceUSDC === 0 || allowanceUSDC < depositAmountUSDC ? (
                          <CustomButton
                            className="btn filledbtn null d-flex justify-content-center align-items-center gap-2"
                            onClick={approveUSDC}
                            // style={{ marginLeft: "10px" }}
                            label="Approve"
                            isDisabled={pending || !startedUSDC}
                            isLoading={pendingAction === "approve"}
                          />
                        ) : (
                          <CustomButton
                            className="btn filledbtn null d-flex justify-content-center align-items-center gap-2"
                            onClick={() => depositUSDC(query.get("ref"))}
                            // style={{ marginLeft: "10px" }}
                            label="Deposit"
                            isDisabled={pending || !startedUSDC}
                            isLoading={pendingAction === "deposit"}
                          />
                        )}

                      </div>
                    </div>
                  </div>
                  <div className="otherside-border col-12 col-md-12 col-lg-4 false pool-deposit-rewards">
                    <div className="d-flex justify-content-between gap-2">
                      <h6 className="withdraw-txt">Rewards</h6>
                      <h6 className="withdraw-littletxt d-flex align-items-center gap-2">
                        Rewards are displayed in real-time
                        <img
                          id="tooltip-usdc-5"
                          data-tooltip-content="  You can claim rewards every 8 hours, and the minimum amount you can claim or re-deposit is 10 USDC. There is no specific timeframe for re-depositing."
                          src="https://app.dypius.com/static/media/more-info.95629bb7.svg"
                          alt=""
                        />
                      </h6>
                    </div>
                    <div className="form-row flex-column flex-lg-row d-flex gap-2 align-item-start align-items-lg-center justify-content-between">
                      <div className="d-flex flex-column">
                        <span
                          style={{
                            fontWeight: 600,
                            fontSize: "13px",
                            lineHeight: "18px",
                            color: "rgb(192, 201, 255)",
                          }}
                        >
                          USDC
                        </span>
                        <div className="d-flex flex-row">
                          <span
                            style={{
                              fontWeight: 600,
                              fontSize: "16px",
                            }}
                          >
                            <p className="" style={{ marginRight: "10px" }}>{rewardUSDC.toFixed(2)}</p>
                          </span>

                          {nextClaimDateUSDC !== 0 && new Date().getTime() < nextClaimDateUSDC * 1000 && (
                            <Countdown
                              date={nextClaimDateUSDC * 1000}
                              renderer={CountdownRenderer}
                            />

                          )}
                          {isClaimableUSDC && (
                            <span style={{ color: "greenyellow", fontSize: "14px" }}>claimable</span>
                          )}
                        </div>
                      </div>
                      <div className="claim-reinvest-container d-flex justify-content-between align-items-center gap-3">

                        <CustomButton
                          className="btn filledbtn d-flex justify-content-center align-items-center gap-2"
                          onClick={claimRewardUSDC}
                          label="Claim"
                          isDisabled={pending || !isClaimableUSDC || !startedUSDC}
                          isLoading={pendingAction === "claim"}
                        />

                        <CustomButton
                          className="btn filledbtn d-flex justify-content-center align-items-center gap-2"
                          onClick={reinvestUSDC}
                          label="Reinvest"
                          isDisabled={pending || !startedUSDC}
                          isLoading={pendingAction === "reinvest"}
                        />

                        {/* <button
                          disabled
                          className="btn outline-btn disabled-btn d-flex justify-content-center align-items-center gap-2"
                          style={{ height: "fit-content" }}
                        >
                          Reinvest
                        </button> */}
                      </div>
                    </div>
                  </div>
                  <div className="otherside-border col-12 col-md-12 col-lg-2 false">
                    <h6 className="deposit-txt d-flex align-items-center gap-2 justify-content-between">
                      WITHDRAW
                      <img
                        id="tooltip-usdc-6"
                        data-tooltip-content="   You can withdraw the full deposited amount at any time. Withdrawing before 30 days incurs a penalty. Once you withdraw the full amount, then your account will be initialized."
                        src="https://app.dypius.com/static/media/more-info.95629bb7.svg"
                        alt=""
                      />
                    </h6>
                    <CustomButton
                      className="btn filledbtn withdrawbtn d-flex justify-content-center align-items-center gap-2"
                      onClick={withdrawUSDC}
                      label="Refund"
                      isDisabled={pending || !startedUSDC || !(userInfoUSDC && userInfoUSDC.totalDeposited > 0)}
                      isLoading={pendingAction === "withdraw"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row m-0 mt-4 gap-4 toppool-allwrapper">
            {/* DAI  */}
            <div
              className={showButtonText1 === "Close" ? "poolscardwrapper cursor-pointer position-relative false selected-border" : "poolscardwrapper cursor-pointer position-relative false"}
              onClick={toggleDeposit1}
            >
              <img
                src={SoonRibbonIcon}
                className="new-pool"
                alt="new pool"
              />
              <div
                className="purplediv"
                style={{ background: "rgb(136, 144, 196)", top: 12 }}
              />
              <div className="d-flex flex-column gap-0">
                <div className="d-flex m-0 justify-content between gap-2 align-items-center justify-content-between title-apr-wrapper">
                  <div className="d-flex align-items-center">
                    <h6 className="token-name d-flex align-items-center gap-2">
                      <img
                        src={DAIIcon}
                        alt=""
                        className="tokenlogo"
                        width={32}
                        height={32}
                      />{" "}
                      DAI
                    </h6>
                  </div>
                  <div className="d-flex align-items-baseline gap-1">
                    <h6 className="apr-amount">{dailyReward}%</h6>
                    <h6 className="apr-title">Daily ROI</h6>
                  </div>
                </div>
                <div className="d-flex m-0 justify-content between gap-2 align-items-center justify-content-between bottomwrapper } ">
                  <div className="d-flex flex-column">
                    <h6 className="tvl-text">Total Current Assets</h6>
                    <h6 className="tvl-amount">${totalCurrentAssets.toFixed(0)}</h6>
                  </div>
                  <div className="d-flex flex-column align-items-end">
                    <h6 className="tvl-text">Lock Time</h6>
                    <h6 className="locktime-amount">30 days</h6>
                  </div>
                </div>
                <div className="details-wrapper">
                  <h6
                    className={
                      deposit1
                        ? "details-text gap-1 d-flex align-items-center"
                        : "details-text gap-1 clr d-flex align-items-center"
                    }
                  >
                    {showButtonText1}
                    <img
                      className={deposit1 ? "rotate-it" : ""}
                      src="https://app.dypius.com/static/media/greenarrow.2715b29d.svg"
                      alt=""
                    />
                  </h6>
                </div>
              </div>
            </div>
          </div>

          {/* DAI detail */}
          <div className={deposit1 ? "container-lg p-0" : "hide-it"}>
            <div className="allwrapper mt-4 selected-border">
              <div className="leftside2 w-100">
                <div className="activewrapper">
                  <div className="d-flex flex-column flex-lg-row w-100 align-items-start align-items-lg-center justify-content-between gap-3 gap-lg-5 pool-details-header">
                    <h6 className="activetxt">
                      {startedBUSD ? <EllipseSvg className="position-relative" style={{ top: "-1px" }} /> : <EllipseSvgGreen className="position-relative" style={{ top: "-1px" }} />}
                      {startedBUSD ? "Active" : "Ready"}
                    </h6>
                    <div className="d-flex flex-row-reverse flex-lg-row align-items-center justify-content-between earnrewards-container">
                      <div className="d-flex flex-column flex-lg-row align-items-end align-items-lg-center gap-3 gap-lg-5">
                        <div className="d-flex  align-items-center justify-content-between gap-2">
                          <h6 className="earnrewards-text">Development fee:</h6>
                          <h6 className="earnrewards-token d-flex align-items-center gap-1">
                            3%
                            <img
                              id="tooltip-busd-1"
                              data-tooltip-content=" A 3% fee is applicable on all deposits, including re-deposits, to maintain, improve, and extend the project."
                              src="https://app.dypius.com/static/media/more-info.95629bb7.svg"
                              alt=""
                            />
                          </h6>
                        </div>
                        <div className="d-flex align-items-center justify-content-between gap-2">
                          <h6 className="earnrewards-text">Daily ROI:</h6>
                          <h6 className="earnrewards-token d-flex align-items-center gap-1">
                            {dailyReward}%
                            <img
                              id="tooltip-busd-2"
                              data-tooltip-content="The daily ROI remains stable as long as the user maintains regular activity."
                              src="https://app.dypius.com/static/media/more-info.95629bb7.svg"
                              alt=""
                            />
                          </h6>
                        </div>
                        <div className="d-flex align-items-center justify-content-between gap-2">
                          <h6 className="earnrewards-text">Lock time:</h6>
                          <h6 className="earnrewards-token d-flex align-items-center gap-1">
                            0 Days
                            <img
                              id="tooltip-busd-3"
                              data-tooltip-content=" There is no locked period for withdrawing the full deposited amount, but withdrawing before a certain period incurs a penalty."
                              src="https://app.dypius.com/static/media/more-info.95629bb7.svg"
                              alt=""
                            />
                          </h6>
                        </div>
                      </div>
                      <div className="d-flex flex-column flex-lg-row align-items-start align-items-lg-center justify-content-between gap-3">
                        {startedBUSD && (<a
                          href={`https://arbiscan.io/address/${YC_CONTRACT()}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <h6 className="bottomitems">
                            <img
                              src="https://app.dypius.com/static/media/arrow-up.987a80d4.svg"
                              alt=""
                            />
                            Verified Contract
                          </h6>
                        </a>)}
                        {/* <a
                          href="https://app.uniswap.org/#/swap?outputCurrency=0x6B175474E89094C44Da98b954EedeAC495271d0F"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <h6 className="bottomitems">
                            <img
                              src="https://app.dypius.com/static/media/arrow-up.987a80d4.svg"
                              alt=""
                            />
                            Get DAI
                          </h6>
                        </a> */}
                        <div onClick={statToggle}>
                          <h6 className="bottomitems">
                            <img
                              src="https://app.dypius.com/static/media/purpleStat.87015297.svg"
                              alt=""
                            />
                            Stats
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pools-details-wrapper d-flex m-0 container-lg border-0">
                <div className="row gap-4 gap-lg-0 w-100 justify-content-between">
                  <div className="firstblockwrapper col-12 col-md-6 col-lg-2">
                    <div className="d-flex flex-row flex-lg-column align-items-center align-items-lg-start justify-content-between gap-4">
                      <h6 className="start-title">Start Staking</h6>

                      <ConnectedWallet />
                    </div>
                  </div>
                  <div className="otherside-border col-12 col-md-12 col-lg-4 pool-deposit-stake">
                    <div className="d-flex justify-content-between align-items-center gap-2">
                      <div className="d-flex justify-content-center align-items-center gap-3">
                        <h6 className="deposit-txt">Deposit</h6>
                        <h6 className="mybalance-text">
                          Balance: <b>{(busdBalance).toFixed(2)} DAI</b>
                        </h6>
                      </div>
                      <div className="d-flex flex-row">
                        <h6 className="mybalance-text" style={{ marginRight: "10px" }}>
                          Staked: <b>{userInfo && userInfo.totalDeposited ? userInfo.totalDeposited.toFixed(2) : 0} DAI</b>
                        </h6>
                        <img
                          id="tooltip-busd-4"
                          data-tooltip-content=" You can earn stable income by depositing a minimum of 20 DAI and allowing our YieldCat to do the rest for you."
                          src="https://app.dypius.com/static/media/more-info.95629bb7.svg"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="d-flex flex-column gap-2 justify-content-between">
                      <div className="d-flex spw align-items-center justify-content-between gap-2">
                        <div className="input-container px-0">
                          <input
                            type="number"
                            autoComplete="off"
                            placeholder="Amount"
                            className="text-input"
                            name="amount_deposit"
                            id="amount_deposit"
                            style={{ width: "100%" }}
                            value={depositAmount}
                            onChange={e => setDepositAmount(e.target.value)}
                          />
                          {/* <label className="label">Amount</label> */}
                        </div>
                        <button className="btn maxbtn" onClick={() => {
                          setDepositAmount(busdBalance)
                        }}>Max</button>
                        {busdAllowance === 0 || busdAllowance < depositAmount ? (
                          <CustomButton
                            className="btn filledbtn null d-flex justify-content-center align-items-center gap-2"
                            onClick={approveDAI}
                            // style={{ marginLeft: "10px" }}
                            label="Approve"
                            isDisabled={pending || !startedBUSD}
                            isLoading={pendingAction === "approve"}
                          />
                        ) : (
                          <CustomButton
                            className="btn filledbtn null d-flex justify-content-center align-items-center gap-2"
                            onClick={() => depositBUSD(query.get("ref"))}
                            // style={{ marginLeft: "10px" }}
                            label="Deposit"
                            isDisabled={pending || !startedBUSD}
                            isLoading={pendingAction === "deposit"}
                          />
                        )}

                      </div>
                    </div>
                  </div>
                  <div className="otherside-border col-12 col-md-12 col-lg-4 false pool-deposit-rewards">
                    <div className="d-flex justify-content-between gap-2">
                      <h6 className="withdraw-txt">Rewards</h6>
                      <h6 className="withdraw-littletxt d-flex align-items-center gap-2">
                        Rewards are displayed in real-time
                        <img
                          id="tooltip-busd-5"
                          data-tooltip-content="  You can claim rewards every 8 hours, and the minimum amount you can claim or re-deposit is 10 DAI. There is no specific timeframe for re-depositing."
                          src="https://app.dypius.com/static/media/more-info.95629bb7.svg"
                          alt=""
                        />
                      </h6>
                    </div>
                    <div className="form-row flex-column flex-lg-row d-flex gap-2 align-item-start align-items-lg-center justify-content-between">
                      <div className="d-flex flex-column">
                        <span
                          style={{
                            fontWeight: 600,
                            fontSize: "13px",
                            lineHeight: "18px",
                            color: "rgb(192, 201, 255)",
                          }}
                        >
                          DAI
                        </span>
                        <div className="d-flex flex-row">
                          <span
                            style={{
                              fontWeight: 600,
                              fontSize: "16px",
                            }}
                          >
                            <p className="" style={{ marginRight: "10px" }}>{reward.toFixed(2)}</p>
                          </span>

                          {nextClaimDate !== 0 && new Date().getTime() < nextClaimDate * 1000 && (
                            <Countdown
                              date={nextClaimDate * 1000}
                              renderer={CountdownRenderer}
                            />

                          )}
                          {isClaimable && (
                            <span style={{ color: "greenyellow", fontSize: "14px" }}>claimable</span>
                          )}
                        </div>
                      </div>
                      <div className="claim-reinvest-container d-flex justify-content-between align-items-center gap-3">

                        <CustomButton
                          className="btn filledbtn d-flex justify-content-center align-items-center gap-2"
                          onClick={claimReward}
                          label="Claim"
                          isDisabled={pending || !isClaimable || !startedBUSD}
                          isLoading={pendingAction === "claim"}
                        />

                        <CustomButton
                          className="btn filledbtn d-flex justify-content-center align-items-center gap-2"
                          onClick={reinvest}
                          label="Reinvest"
                          isDisabled={pending || !startedBUSD}
                          isLoading={pendingAction === "reinvest"}
                        />

                        {/* <button
                          disabled
                          className="btn outline-btn disabled-btn d-flex justify-content-center align-items-center gap-2"
                          style={{ height: "fit-content" }}
                        >
                          Reinvest
                        </button> */}
                      </div>
                    </div>
                  </div>
                  <div className="otherside-border col-12 col-md-12 col-lg-2 false">
                    <h6 className="deposit-txt d-flex align-items-center gap-2 justify-content-between">
                      WITHDRAW
                      <img
                        id="tooltip-busd-6"
                        data-tooltip-content="   You can withdraw the full deposited amount at any time. Withdrawing before 30 days incurs a penalty. Once you withdraw the full amount, then your account will be initialized."
                        src="https://app.dypius.com/static/media/more-info.95629bb7.svg"
                        alt=""
                      />
                    </h6>
                    <CustomButton
                      className="btn filledbtn withdrawbtn d-flex justify-content-center align-items-center gap-2"
                      onClick={withdrawBUSD}
                      label="Refund"
                      isDisabled={pending || !startedBUSD || !(userInfo && userInfo.totalDeposited > 0)}
                      isLoading={pendingAction === "withdraw"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      {/* DAI */}
      <div
        className={statm ? "modal fade show" : "hide-it"}
        style={{ display: "block", background: "rgba(0, 0, 0, 0.5)" }}
      >
        <div className="modal-dialog tymodal">
          <div className="modal-content" style={{ width: "fit-content" }}>
            <div className="modal-header justify-content-between align-items-center">
              <div className="d-flex align-items-center gap-2">
                <h6
                  style={{
                    fontWeight: 600,
                    fontSize: "20px",
                    lineHeight: "28px",
                    color: "white",
                  }}
                >
                  $DAI Earning
                </h6>
              </div>
              <img
                onClick={statToggle}
                src="https://app.dypius.com/static/media/xMark.97d8a88a.svg"
                style={{ cursor: "pointer" }}
              />
            </div>
            {userInfo && (
              <div className="modal-body">
                <div className="earn-hero-content p4token-wrapper">
                  <div className="l-box pl-3 pr-3">
                    <div className="  px-0">
                      <div className="stats-container my-4">
                        <div className="stats-card p-4 d-flex flex-column mx-auto w-100">
                          <span className="stats-card-title">Daily ROI</span>
                          <h6 className="stats-card-content">{dailyReward}%</h6>
                        </div>
                        <div className="stats-card p-4 d-flex flex-column mx-auto w-100">
                          <span className="stats-card-title">
                            Total Deposit
                          </span>
                          <h6 className="stats-card-content">{userInfo.totalDeposited.toFixed(2)} DAI</h6>
                        </div>
                        <div className="stats-card p-4 d-flex flex-column mx-auto w-100">
                          <span className="stats-card-title">
                            Referral Earnings
                          </span>
                          <h6 className="stats-card-content">{userInfo.totalReferralEarnings.toFixed(2)} DAI</h6>
                        </div>
                        <div className="stats-card p-4 d-flex flex-column mx-auto w-100">
                          <span className="stats-card-title">
                            Claimed Rewards
                          </span>
                          <h6 className="stats-card-content">{userInfo.totalClaimedRewards.toFixed(2)} DAI</h6>
                        </div>
                        <div className="stats-card p-4 d-flex flex-column mx-auto w-100">
                          <span className="stats-card-title">
                            Daily Rewards
                          </span>
                          <h6 className="stats-card-content">{userInfo.dailyRewards.toFixed(2)} DAI</h6>
                        </div>
                        <div className="stats-card p-4 d-flex flex-column mx-auto w-100">
                          <span className="stats-card-title">
                            Unclaimed Rewards
                          </span>
                          <h6 className="stats-card-content">{userInfo.unclaimedReward.toFixed(2)} DAI</h6>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="referralwrapper col-8">
                          <div className="d-flex gap-2 align-items-start justify-content-between">
                            <img
                              src="https://app.dypius.com/static/media/referral.5fd49c5b.svg"
                              alt=""
                            />
                            <div
                              className="d-flex gap-2 flex-column"
                              style={{ width: "60%" }}
                            >
                              <div className="d-flex w-100">
                                <div style={{ fontSize: "0.8rem" }}>
                                  <h6
                                    className="referraltitle"
                                    style={{ cursor: "pointer" }}
                                  />
                                  <h6
                                    className="referraltitle"
                                    data-event="click"
                                    data-for="0.h9bak3v6own"
                                    data-tip="Copied To Clipboard!"
                                    currentitem="false"
                                  >
                                    Referral Link:
                                  </h6>
                                </div>
                                <div style={{ fontSize: "0.8rem", flex: 1, margin: "auto", marginLeft: "10px" }}
                                  className="truncate">{refLink}</div>
                              </div>
                              <h6 className="referraldesc">
                                Earn a referral bonus by referring a friend to YieldCat. You will receive an instant bonus equivalent to 5% of the deposited amount.
                              </h6>
                            </div>
                            <div
                              data-event="click"
                              data-for="0.h9bak3v6own"
                              data-tip="Copied To Clipboard!"
                              currentitem="false"
                            >
                              <button className="copybtn btn" onClick={(e) => onCopyLink(e, refLink)}>
                                <img
                                  src="https://app.dypius.com/static/media/copy.131f6edd.svg"
                                  alt=""

                                />{" "}
                                Copy{" "}
                              </button>{" "}
                            </div>
                            <div
                              className="__react_component_tooltip tb4ca7fed-bd24-4e57-8f68-60cbf0a33632 place-top type-dark"
                              id="0.h9bak3v6own"
                              data-id="tooltip"
                            ></div>
                            &nbsp;{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

          </div>
        </div>
      </div>
      {/* USDC */}
      <div
        className={statusDialogUSDC ? "modal fade show" : "hide-it"}
        style={{ display: "block", background: "rgba(0, 0, 0, 0.5)" }}
      >
        <div className="modal-dialog tymodal">
          <div className="modal-content" style={{ width: "fit-content" }}>
            <div className="modal-header justify-content-between align-items-center">
              <div className="d-flex align-items-center gap-2">
                <h6
                  style={{
                    fontWeight: 600,
                    fontSize: "20px",
                    lineHeight: "28px",
                    color: "white",
                  }}
                >
                  $USDC Earning
                </h6>
              </div>
              <img
                onClick={statToggleUSDC}
                src="https://app.dypius.com/static/media/xMark.97d8a88a.svg"
                style={{ cursor: "pointer" }}
              />
            </div>
            {userInfoUSDC && (
              <div className="modal-body">
                <div className="earn-hero-content p4token-wrapper">
                  <div className="l-box pl-3 pr-3">
                    <div className="  px-0">
                      <div className="stats-container my-4">
                        <div className="stats-card p-4 d-flex flex-column mx-auto w-100">
                          <span className="stats-card-title">Daily ROI</span>
                          <h6 className="stats-card-content">{dailyRewardUSDC}%</h6>
                        </div>
                        <div className="stats-card p-4 d-flex flex-column mx-auto w-100">
                          <span className="stats-card-title">
                            Total Deposit
                          </span>
                          <h6 className="stats-card-content">{userInfoUSDC.totalDeposited.toFixed(2)} USDC</h6>
                        </div>
                        <div className="stats-card p-4 d-flex flex-column mx-auto w-100">
                          <span className="stats-card-title">
                            Referral Earnings
                          </span>
                          <h6 className="stats-card-content">{userInfoUSDC.totalReferralEarnings.toFixed(2)} USDC</h6>
                        </div>
                        <div className="stats-card p-4 d-flex flex-column mx-auto w-100">
                          <span className="stats-card-title">
                            Claimed Rewards
                          </span>
                          <h6 className="stats-card-content">{userInfoUSDC.totalClaimedRewards.toFixed(2)} USDC</h6>
                        </div>
                        <div className="stats-card p-4 d-flex flex-column mx-auto w-100">
                          <span className="stats-card-title">
                            Daily Rewards
                          </span>
                          <h6 className="stats-card-content">{userInfoUSDC.dailyRewards.toFixed(2)} USDC</h6>
                        </div>
                        <div className="stats-card p-4 d-flex flex-column mx-auto w-100">
                          <span className="stats-card-title">
                            Unclaimed Rewards
                          </span>
                          <h6 className="stats-card-content">{userInfoUSDC.unclaimedReward.toFixed(2)} USDC</h6>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="referralwrapper col-8">
                          <div className="d-flex gap-2 align-items-start justify-content-between">
                            <img
                              src="https://app.dypius.com/static/media/referral.5fd49c5b.svg"
                              alt=""
                            />
                            <div
                              className="d-flex gap-2 flex-column"
                              style={{ width: "60%" }}
                            >
                              <div className="d-flex w-100">
                                <div style={{ fontSize: "0.8rem" }}>
                                  <h6
                                    className="referraltitle"
                                    style={{ cursor: "pointer" }}
                                  />
                                  <h6
                                    className="referraltitle"
                                    data-event="click"
                                    data-for="0.h9bak3v6own"
                                    data-tip="Copied To Clipboard!"
                                    currentitem="false"
                                  >
                                    Referral Link:
                                  </h6>
                                </div>
                                <div style={{ fontSize: "0.8rem", flex: 1, margin: "auto", marginLeft: "10px" }}
                                  className="truncate">{refLink}</div>
                              </div>
                              <h6 className="referraldesc">
                                Earn a referral bonus by referring a friend to YieldCat. You will receive an instant bonus equivalent to 5% of the deposited amount.
                              </h6>
                            </div>
                            <div
                              data-event="click"
                              data-for="0.h9bak3v6own"
                              data-tip="Copied To Clipboard!"
                              currentitem="false"
                            >
                              <button className="copybtn btn" onClick={(e) => onCopyLink(e, refLink)}>
                                <img
                                  src="https://app.dypius.com/static/media/copy.131f6edd.svg"
                                  alt=""

                                />{" "}
                                Copy{" "}
                              </button>{" "}
                            </div>
                            <div
                              className="__react_component_tooltip tb4ca7fed-bd24-4e57-8f68-60cbf0a33632 place-top type-dark"
                              id="0.h9bak3v6own"
                              data-id="tooltip"
                            ></div>
                            &nbsp;{" "}
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

          </div>
        </div>
      </div>
      {/* USDT */}
      <div
        className={statusDialogUSDT ? "modal fade show" : "hide-it"}
        style={{ display: "block", background: "rgba(0, 0, 0, 0.5)" }}
      >
        <div className="modal-dialog tymodal">
          <div className="modal-content" style={{ width: "fit-content" }}>
            <div className="modal-header justify-content-between align-items-center">
              <div className="d-flex align-items-center gap-2">
                <h6
                  style={{
                    fontWeight: 600,
                    fontSize: "20px",
                    lineHeight: "28px",
                    color: "white",
                  }}
                >
                  $USDT Earning
                </h6>
              </div>
              <img
                onClick={statToggleUSDT}
                src="https://app.dypius.com/static/media/xMark.97d8a88a.svg"
                style={{ cursor: "pointer" }}
              />
            </div>
            {userInfoUSDT && (
              <div className="modal-body">
                <div className="earn-hero-content p4token-wrapper">
                  <div className="l-box pl-3 pr-3">
                    <div className="  px-0">
                      <div className="stats-container my-4">
                        <div className="stats-card p-4 d-flex flex-column mx-auto w-100">
                          <span className="stats-card-title">Daily ROI</span>
                          <h6 className="stats-card-content">{dailyRewardUSDT}%</h6>
                        </div>
                        <div className="stats-card p-4 d-flex flex-column mx-auto w-100">
                          <span className="stats-card-title">
                            Total Deposit
                          </span>
                          <h6 className="stats-card-content">{userInfoUSDT.totalDeposited.toFixed(2)} USDT</h6>
                        </div>
                        <div className="stats-card p-4 d-flex flex-column mx-auto w-100">
                          <span className="stats-card-title">
                            Referral Earnings
                          </span>
                          <h6 className="stats-card-content">{userInfoUSDT.totalReferralEarnings.toFixed(2)} USDT</h6>
                        </div>
                        <div className="stats-card p-4 d-flex flex-column mx-auto w-100">
                          <span className="stats-card-title">
                            Claimed Rewards
                          </span>
                          <h6 className="stats-card-content">{userInfoUSDT.totalClaimedRewards.toFixed(2)} USDT</h6>
                        </div>
                        <div className="stats-card p-4 d-flex flex-column mx-auto w-100">
                          <span className="stats-card-title">
                            Daily Rewards
                          </span>
                          <h6 className="stats-card-content">{userInfoUSDT.dailyRewards.toFixed(2)} USDT</h6>
                        </div>
                        <div className="stats-card p-4 d-flex flex-column mx-auto w-100">
                          <span className="stats-card-title">
                            Unclaimed Rewards
                          </span>
                          <h6 className="stats-card-content">{userInfoUSDT.unclaimedReward.toFixed(2)} USDT</h6>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="referralwrapper col-8">
                          <div className="d-flex gap-2 align-items-start justify-content-between">
                            <img
                              src="https://app.dypius.com/static/media/referral.5fd49c5b.svg"
                              alt=""
                            />
                            <div
                              className="d-flex gap-2 flex-column"
                              style={{ width: "60%" }}
                            >
                              <div className="d-flex w-100">
                                <div style={{ fontSize: "0.8rem" }}>
                                  <h6
                                    className="referraltitle"
                                    style={{ cursor: "pointer" }}
                                  />
                                  <h6
                                    className="referraltitle"
                                    data-event="click"
                                    data-for="0.h9bak3v6own"
                                    data-tip="Copied To Clipboard!"
                                    currentitem="false"
                                  >
                                    Referral Link:
                                  </h6>
                                </div>
                                <div style={{ fontSize: "0.8rem", flex: 1, margin: "auto", marginLeft: "10px" }}
                                  className="truncate">{refLink}</div>
                              </div>
                              <h6 className="referraldesc">
                                Earn a referral bonus by referring a friend to YieldCat. You will receive an instant bonus equivalent to 5% of the deposited amount.
                              </h6>
                            </div>
                            <div
                              data-event="click"
                              data-for="0.h9bak3v6own"
                              data-tip="Copied To Clipboard!"
                              currentitem="false"
                            >
                              <button className="copybtn btn" onClick={(e) => onCopyLink(e, refLink)}>
                                <img
                                  src="https://app.dypius.com/static/media/copy.131f6edd.svg"
                                  alt=""

                                />{" "}
                                Copy{" "}
                              </button>{" "}
                            </div>
                            <div
                              className="__react_component_tooltip tb4ca7fed-bd24-4e57-8f68-60cbf0a33632 place-top type-dark"
                              id="0.h9bak3v6own"
                              data-id="tooltip"
                            ></div>
                            &nbsp;{" "}
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

          </div>
        </div>
      </div>
    </>
  );
};

export default Pools;
