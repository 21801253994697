import React, { useEffect, useState } from "react";
import "./earnHero.css";
import BSCIcon from "../../../../assets/img/bsc-icon.svg";
import PolkaIcon from "../../../../assets/img/polka-icon.png";
import AptosIcon from "../../../../assets/img/aptos-icon.png";
import ArbitrumIcon from "../../../../assets/img/arbitrum-logo.png";
import AuroraIcon from "../../../../assets/img/aurora.png";
import EthIcon from "../../../../assets/img/eth-icon.png";
import LogoCoin from "../../../../assets/img/logo-coin.png";
import BigNumber from "bignumber.js";
import { useWeb3Contracts } from "web3/contracts";
import axios from "axios";


const EarnHero = () => {

  const {
    stakingContract,
    trustYieldUSDC,
    trustYieldUSDT
  } = useWeb3Contracts();

  const [decimals, setDecimals] = useState(18);
  const [decimalsUSDC, setDecimalsUSDC] = useState(6);
  const [decimalsUSDT, setDecimalsUSDT] = useState(6);

  const [totalPayout, setTotalPayout] = useState(0);

  const [totalPayoutBUSD, setTotalPayoutBUSD] = useState(0);
  const [totalPayoutUSDC, setTotalPayoutUSDC] = useState(0);
  const [totalPayoutUSDT, setTotalPayoutUSDT] = useState(0);


  useEffect(() => {
    setDecimals(stakingContract.decimals);
  }, [stakingContract.decimals]);

  useEffect(() => {
    setTotalPayoutBUSD(new BigNumber(stakingContract.totalPayout).div(10 ** decimals).toNumber());

  }, [stakingContract.totalPayout]);

  useEffect(() => {
    setTotalPayoutUSDC(new BigNumber(trustYieldUSDC.totalPayout).div(10 ** decimalsUSDC).toNumber());

  }, [trustYieldUSDC.totalPayout]);

  useEffect(() => {
    setTotalPayoutUSDT(new BigNumber(trustYieldUSDT.totalPayout).div(10 ** decimalsUSDT).toNumber());

  }, [trustYieldUSDT.totalPayout]);

  useEffect(async () => {
    const resp = await axios.get(`${process.env.REACT_APP_API_URL}/monitor`);
    let po = 0;
    if (resp.status === 200) {
      po = resp.data.message.po;
    }

    setTotalPayout(totalPayoutBUSD + totalPayoutUSDC + totalPayoutUSDT + po);

  }, [totalPayoutBUSD, totalPayoutUSDC, totalPayoutUSDT]);

  return (
    <div className="row w-100 mx-0 flex-column flex-lg-row earn-hero gap-4 gap-lg-0 p-3 p-lg-4 justify-content-between">
      <div className="col-12 col-lg-5 px-0 px-lg-2 d-flex flex-column justify-content-center gap-3">
        <h6 className="text-white tsc primary-text gradient-title" style={{ whiteSpace: "pre" }}>
          YieldCat Earn
        </h6>
        <p className="text-white tcxs">
          By leveraging cutting-edge technology and a robust arbitrage strategy, YieldCat is able to provide a reliable and profitable investment option for users looking to enter the crypto market.
          The system provides a daily reward of 1.5%.
        </p>
      </div>
      <div className="col-12 col-lg-6 px-0 px-lg-2 d-flex gap-0 gap-lg-5">
        <div className="d-flex flex-column align-items-start">
          {/* <div className="d-flex flex-column paid-rewards">
            <p
              style={{
                fontSize: "9px",
                color: "rgb(247, 247, 252)",
                fontWeight: 600,
                fontSize: 16
              }}
            >
              Rewards Paid Out
            </p>
            <span
              className="count-up"
              style={{
                fontSize: "19px",
                color: "rgb(247, 247, 252)",
                fontWeight: 600,
                textAlign: "start",
              }}
            >
              ${totalPayout.toFixed(0)}
            </span>
          </div> */}
          <br />
          <img
            src="https://app.dypius.com/static/media/earnHeroStats.c9f53e39.webp"
            alt=""
            style={{ width: "230px", height: "80px" }}
          />
        </div>

        <div className="d-flex flex-column justify-content-between">
          <div className="d-flex justify-content-start align-items-center gap-2">
            <img
              src={ArbitrumIcon}
              alt=""
              className="network-icon"
            />
            <h4 style={{ color: "rgb(100, 100, 255)", fontWeight: 600, fontSize: 16 }}>
              Active
              {/* Fri, 3 pm UTC */}
            </h4>
          </div>

          <div className="d-flex justify-content-start align-items-center gap-2">
            <img
              src={EthIcon}
              alt=""
              className="network-icon"
            />
            <h4 style={{ color: "rgb(247, 247, 252)", fontWeight: 600, fontSize: 16 }}>
              Coming
            </h4>
          </div>

          <div className="d-flex justify-content-start align-items-center gap-2">
            <img
              src={AuroraIcon}
              alt=""
              className="network-icon"
            />
            <h4 style={{ color: "rgb(247, 247, 252)", fontWeight: 600, fontSize: 16 }}>
              Coming
            </h4>
          </div>
        </div>
        <div className="position-relative d-none d-xxl-block">
          <img
            src={LogoCoin}
            alt=""
            className="coin"
          />
          <img
            src="https://app.dypius.com/static/media/coinBackground.25219c3d.webp"
            alt=""
            className="coin"
          />
        </div>
      </div>
    </div>
  );
};

export default EarnHero;
