import React from "react";
import "./news.css";

import newsImage from "assets/img/newsbanner.png"
import stablecoinImage from "assets/img/STABLECOINS.png"
import newsYieldBlog from "assets/img/news-yield-blog.jpg"

const News = () => {
  return (
    <div className="align-items-center justify-content-between ">
      <div className="col-12 p-0">
        <h6 className="top-pools-title mb-2 gradient-title">Latest News</h6>
        <div className="d-flex flex-column flex-lg-row gap-3 justify-content-between px-0">
          {" "}
          <div
            href="#"
            className="newscard-wrapper d-flex"
            target="_blank"
          >
            <div className="d-flex flex-column vbd flex-lg-row justify-content-between align-items-center gap-2 position-relative">
              <img
                alt=""
                className="newsimg2"
                src={newsImage}
              />
              <a
                href="https://yieldcat-labs-organization.gitbook.io/whitepaper/product-guides/how-projects-work"
                // className="newscard-wrapper d-flex"
                target="_blank"
              >
                <div className="d-flex flex-column gap-3 justify-content-between">
                  <h6 className="nc-hot-trend">
                    {" "}
                    <img
                      src="https://app.dypius.com/static/media/sparkle.1db7745a.svg"
                      alt=""
                    />{" "}
                    Hot Trending
                  </h6>
                  <h6 className="nc-title">
                    Introducing How YieldCat works | Generates 1.5% Daily Rewards
                  </h6>
                  <div className="d-flex m-0 justify-content-between align-items-center">
                    <h6 className="nc-date">
                      {" "}
                      <img
                        src="https://app.dypius.com/static/media/calendar.c9c86fbc.svg"
                        alt=""
                      />
                      March 5, 2023
                    </h6>
                    <img
                      src="https://app.dypius.com/static/media/filledArrow.a41baa76.svg"
                      alt=""
                      className=""
                    />
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>

        <div className="d-flex flex-column flex-lg-row gap-3 justify-content-between px-0 mt-3">
          {" "}
          <div
            href="#"
            className="newscard-wrapper d-flex"
            target="_blank"
          >
            <div className="d-flex flex-column vbd flex-lg-row justify-content-between align-items-center gap-2 position-relative">
              <img
                alt=""
                className="newsimg2"
                src={stablecoinImage}
              />
              <a
                href="https://jamesbachini.com/stablecoins/"
                // className="newscard-wrapper d-flex"
                target="_blank"
              >
                <div className="d-flex flex-column gap-3 justify-content-between">
                  <h6 className="nc-hot-trend">
                    {" "}
                    <img
                      src="https://app.dypius.com/static/media/sparkle.1db7745a.svg"
                      alt=""
                    />{" "}
                    Stablecoins
                  </h6>
                  <h6 className="nc-title">
                    USD Stablecoins Compared | USDT vs USDC vs UST vs MIM vs DAI vs FRAX
                  </h6>
                  <div className="d-flex m-0 justify-content-between align-items-center">
                    <h6 className="nc-date">
                      {" "}
                      <img
                        src="https://app.dypius.com/static/media/calendar.c9c86fbc.svg"
                        alt=""
                      />
                      December 1, 2022
                    </h6>
                    <img
                      src="https://app.dypius.com/static/media/filledArrow.a41baa76.svg"
                      alt=""
                      className=""
                    />
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>

        <div className="d-flex flex-column flex-lg-row gap-3 justify-content-between px-0 mt-3">
          {" "}
          <div
            href="#"
            className="newscard-wrapper d-flex"
            target="_blank"
          >
            <div className="d-flex flex-column vbd flex-lg-row justify-content-between align-items-center gap-2 position-relative">
              <img
                alt=""
                className="newsimg2"
                src={newsYieldBlog}
              />
              <a
                href="https://jamesbachini.com/yield/"
                // className="newscard-wrapper d-flex"
                target="_blank"
              >
                <div className="d-flex flex-column gap-3 justify-content-between">
                  <h6 className="nc-hot-trend">
                    {" "}
                    <img
                      src="https://app.dypius.com/static/media/sparkle.1db7745a.svg"
                      alt=""
                    />{" "}
                    Yield Farming
                  </h6>
                  <h6 className="nc-title">
                    The Truth About Where Yield Comes From In DeFi(Interest rates in traditional banks are 0.1%, have you ever wondered how DeFi yield farmers are getting 20-100% yields on their digital assets?)
                  </h6>
                  <div className="d-flex m-0 justify-content-between align-items-center">
                    <h6 className="nc-date">
                      {" "}
                      <img
                        src="https://app.dypius.com/static/media/calendar.c9c86fbc.svg"
                        alt=""
                      />
                      June 1, 2022
                    </h6>
                    <img
                      src="https://app.dypius.com/static/media/filledArrow.a41baa76.svg"
                      alt=""
                      className=""
                    />
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default News;
