import React, { useState, useEffect } from "react";
import "./stakingOptions.css";
import BigNumber from "bignumber.js";
import { useWeb3Contracts } from "web3/contracts";

const StakingOptions = () => {

  const {
    stakingContract,
    trustYieldUSDC,
    trustYieldUSDT
  } = useWeb3Contracts();

  const [decimals, setDecimals] = useState(18);
  const [totalCurrentAssetsBUSD, setTotalCurrentAssetsBUSD] = useState(0);
  const [totalCurrentAssetsUSDC, setTotalCurrentAssetsUSDC] = useState(0);
  const [totalCurrentAssetsUSDT, setTotalCurrentAssetsUSDT] = useState(0);

  useEffect(() => {
    setDecimals(stakingContract.decimals);
  }, [stakingContract.decimals]);

  useEffect(() => {
    setTotalCurrentAssetsBUSD(new BigNumber(stakingContract.totalCurrentAssets).div(10 ** decimals).toNumber());
  }, [stakingContract.totalCurrentAssets]);

  useEffect(() => {
    setTotalCurrentAssetsUSDC(new BigNumber(trustYieldUSDC.totalCurrentAssets).div(10 ** decimals).toNumber());
  }, [trustYieldUSDC.totalCurrentAssets]);

  useEffect(() => {
    setTotalCurrentAssetsUSDT(new BigNumber(trustYieldUSDT.totalCurrentAssets).div(10 ** decimals).toNumber());
  }, [trustYieldUSDT.totalCurrentAssets]);

  return (
    <div className="row justify-content-center w-100">
      <div
        className="row justify-content-between align-items-center p-2 options-container"
        style={{ marginTop: "30px", marginBottom: "40px" }}
      >
        <div className="col-8 row d-flex ms-1 mka gap-0 gap-xl-3 justify-content-center p-2">
          <div className="earn-option col-3 col-xl-2 d-flex align-items-center justify-content-center earn-option-active">
            <img
              src="https://app.dypius.com/static/media/stakingIcon.ea7cdcdf.svg"
              alt=""
            />
            Staking
          </div>
        </div>
        {/*
        <div className="col-2 d-flex justify-content-end align-items-center gap-1 gap-lg-3">
          <h5 className="text-white inactive-pools">Inactive pools</h5>
          <div className="pill-box false">
            <div className="pill" />
          </div>
        </div>
        */}
      </div>
      <div
        className="row align-items-center gap-5 gap-lg-0 justify-content-between px-0 d-flex"
        style={{ minHeight: "55px" }}
      >
        <div className="col-12 col-lg-4 col-xl-3 px-0">
          <div className="total-value-locked-container p-2 d-flex justify-content-between align-items-center">
            <span style={{ fontWeight: 600, fontSize: "13px" }}>
              Total Assets (All Pools)
            </span>
            <h6
              className="text-white"
              style={{ fontWeight: 600, fontSize: "22px" }}
            >
              ${(totalCurrentAssetsBUSD + totalCurrentAssetsUSDC + totalCurrentAssetsUSDT).toFixed(0)}
            </h6>
          </div>
        </div>
        <div className="col-12 col-lg-8 col-xl-6 d-flex mka gap-3 justify-content-around justify-content-lg-end justify-content-xl-center px-0 px-xl-2">
          <div className="stake-item position-relative flex-column flex-lg-row d-flex align-items-center gap-2 null bnb-item-active">
            <img
              src="https://app.dypius.com/static/media/bnbStakeActive.0abf7d44.svg"
              alt=""
            />
            <div className="d-flex flex-column align-items-center align-items-lg-start">
              <p
                className="text-white"
                style={{ fontSize: "14px", fontWeight: 600 }}
              >
                BNB Chain
              </p>
            </div>
          </div>
        </div>
        <div className="col-3" />
      </div>
      <div className="my-2" />
    </div>
  );
};

export default StakingOptions;
