import React, { useState } from "react";
import "./faq.css";

const Faq = () => {
  const [faqOne, setfaqOne] = useState(false);
  const [faqTwo, setfaqTwo] = useState(false);
  const [faqThree, setfaqThree] = useState(false);
  const [faqFour, setfaqFour] = useState(false);
  const [faqFive, setfaqFive] = useState(false);
  const [faqSix, setfaqSix] = useState(false);
  const [faqSeven, setfaqSeven] = useState(false);
  const [faqEight, setfaqEight] = useState(false);
  const [faqNine, setfaqNine] = useState(false);
  const [faqTen, setfaqTen] = useState(false);
  const [faqEleven, setfaqEleven] = useState(false);
  const [faqTwelve, setfaqTwelve] = useState(false);
  let faq1 = () => {
    if (faqOne == false) {
      setfaqOne(true);
    } else {
      setfaqOne(false);
    }
  };
  let faq2 = () => {
    if (faqTwo == false) {
      setfaqTwo(true);
    } else {
      setfaqTwo(false);
    }
  };
  let faq3 = () => {
    if (faqThree == false) {
      setfaqThree(true);
    } else {
      setfaqThree(false);
    }
  };
  let faq4 = () => {
    if (faqFour == false) {
      setfaqFour(true);
    } else {
      setfaqFour(false);
    }
  };
  let faq5 = () => {
    if (faqFive == false) {
      setfaqFive(true);
    } else {
      setfaqFive(false);
    }
  };
  let faq6 = () => {
    if (faqSix == false) {
      setfaqSix(true);
    } else {
      setfaqSix(false);
    }
  };
  let faq7 = () => {
    if (faqSeven == false) {
      setfaqSeven(true);
    } else {
      setfaqSeven(false);
    }
  };
  let faq8 = () => {
    if (faqEight == false) {
      setfaqEight(true);
    } else {
      setfaqEight(false);
    }
  };
  let faq9 = () => {
    if (faqNine == false) {
      setfaqNine(true);
    } else {
      setfaqNine(false);
    }
  };
  let faq10 = () => {
    if (faqTen == false) {
      setfaqTen(true);
    } else {
      setfaqTen(false);
    }
  };
  let faq11 = () => {
    if (faqEleven == false) {
      setfaqEleven(true);
    } else {
      setfaqEleven(false);
    }
  };
  let faq12 = () => {
    if (faqTwelve == false) {
      setfaqTwelve(true);
    } else {
      setfaqTwelve(false);
    }
  };

  return (
    <div
      id="earnfaq"
      className="flex-column-reverse faq-container justify-content-between"
    >
      <div className="col-12 px-0 px-lg-2 ps-xl-0">
        <h6 className="mb-2  gradient-title">
          YieldCat FAQs
        </h6>
        <div className="faq-items-container p-3">
          <div className="faqwrapper">
            <h2 onClick={faq1} id="headingOne">
              <button
                aria-controls="example-collapse-text"
                aria-expanded={-1}
                className={
                  faqOne ? "headingtitle-active" : "headingtitle-passive"
                }
                style={{ borderRadius: "8px 8px 0px 0px" }}
              >
                What is YieldCat?
                <img
                  className={faqOne ? "rotate-it" : ""}
                  src="https://app.dypius.com/static/media/arrowPassive.de81a0d7.svg"
                  alt=""
                />
              </button>
            </h2>
            <div
              id="example-collapse-text"
              className={faqOne ? "collapse  show" : "collapse hide-it show"}
            >
              <p className="mb-0 faq-content">
                YieldCat is a revolutionary stablecoin yield system designed to offer investors a predictable return on their investment that is changing the game in the crypto market.  .
              </p>
            </div>
          </div>



          <div className="faqwrapper">
            <h2 onClick={faq3} id="">
              <button
                aria-controls="example-collapse-text"
                aria-expanded={-1}
                className={
                  faqThree ? "headingtitle-active" : "headingtitle-passive"
                }
                style={{ borderRadius: "0px" }}
              >
                When Can I Claim The Stablecoin Reward?
                <img
                  className={faqThree ? "rotate-it" : ""}
                  src="https://app.dypius.com/static/media/arrowPassive.de81a0d7.svg"
                  alt=""
                />
              </button>
              <div
                id="example-collapse-text"
                className={
                  faqThree ? "collapse  show" : "collapse hide-it show"
                }
              >
                <p className="mb-0 faq-content">
                  YieldCat offers users the opportunity to invest in stable coins and receive daily rewards equivalent to 1.5% of their invested amount.
                  <br />  <br />
                  Investors can claim their rewards every 8 hours, providing flexibility in their investment strategy. For example, they may deposit their investment before leaving for work and withdraw upon returning home, or invest before sleeping and claim their reward upon waking up.
                </p>
              </div>
            </h2>
          </div>

          <div className="faqwrapper">
            <h2 onClick={faq6}>
              <button
                aria-controls="example-collapse-text"
                aria-expanded={-1}
                className={
                  faqSix ? "headingtitle-active" : "headingtitle-passive"
                }
                style={{ borderRadius: "0px" }}
              >
                What is The Advantage of Reinvesting?
                <img
                  className={faqSix ? "rotate-it" : ""}
                  src="https://app.dypius.com/static/media/arrowPassive.de81a0d7.svg"
                  alt=""
                />
              </button>
              <div
                id="example-collapse-text"
                className={faqSix ? "collapse  show" : "collapse hide-it show"}
              >
                <p className="mb-0 faq-content">
                  Reinvesting allows users to increase their rewards without claiming and depositing again, thus saving on gas fees. This also increases their total invested amount with small investments, resulting in an increased reward.
                  You check the detail in <a href="https://yieldcat-labs-organization.gitbook.io/whitepaper/product-guides/revenue-strategy" target="_blank" style={{ color: "yellow" }}>revenue strategy</a> section.
                </p>
              </div>
            </h2>
          </div>

          <div className="faqwrapper">
            <h2 onClick={faq4}>
              <button
                aria-controls="example-collapse-text"
                aria-expanded={-1}
                className={
                  faqFour ? "headingtitle-active" : "headingtitle-passive"
                }
                style={{ borderRadius: "0px" }}
              >
                What is $YCAT Token?
                <img
                  className={faqFour ? "rotate-it" : ""}
                  src="https://app.dypius.com/static/media/arrowPassive.de81a0d7.svg"
                  alt=""
                />
              </button>
              <div
                id="example-collapse-text"
                className={faqFour ? "collapse  show" : "collapse hide-it show"}
              >
                <p className="mb-0 faq-content">
                  The $YCAT token is the native token in YieldCat Lab's ecosystem, functioning as a utility token.

                  It will be distributed to investors and users, and will serve as the primary payment, utility, and governance token across various platforms such as YieldCat App and the AI-based Arbitrage bot within the ecosystem.
                  You can check the <a href="https://yieldcat-labs-organization.gitbook.io/whitepaper/overview/tokenomics" target="_blank" style={{ color: "yellow" }}>tokenomics</a> here in detail.
                </p>
              </div>
            </h2>
          </div>

          <div className="faqwrapper">
            <h2 onClick={faq5}>
              <button
                aria-controls="example-collapse-text"
                aria-expanded={-1}
                className={
                  faqFive ? "headingtitle-active" : "headingtitle-passive"
                }
                style={{ borderRadius: "0px" }}
              >
                How Can I Join $YCAT Token Private Seed?
                <img
                  className={faqFive ? "rotate-it" : ""}
                  src="https://app.dypius.com/static/media/arrowPassive.de81a0d7.svg"
                  alt=""
                />
              </button>
              <div
                id="example-collapse-text"
                className={faqFive ? "collapse  show" : "collapse hide-it show"}
              >
                <p className="mb-0 faq-content">
                  Investors have the option to join the $YCAT token private seed, which can be found in detail on the tokenomics page. The token price in the private seed is set at $0.05 USD(50% discount).
                  <br /><br />
                  <a href="https://yieldcat-labs-organization.gitbook.io/whitepaper/product-guides/join-private-seed" target="_blank" style={{ color: "yellow" }}>To join the private seed</a>, investors must use their rewards instead of claiming them. Additionally, the amount must be greater than $1,000 USD to be eligible to participate.
                </p>
              </div>
            </h2>
          </div>

          <div className="faqwrapper">
            <h2 onClick={faq7}>
              <button
                aria-controls="example-collapse-text"
                aria-expanded={-1}
                className={
                  faqSeven ? "headingtitle-active" : "headingtitle-passive"
                }
                style={{ borderRadius: "0px" }}
              >
                Why Arbitrum is Our First Chain?
                <img
                  className={faqSeven ? "rotate-it" : ""}
                  src="https://app.dypius.com/static/media/arrowPassive.de81a0d7.svg"
                  alt=""
                />
              </button>
              <div
                id="example-collapse-text"
                className={
                  faqSeven ? "collapse  show" : "collapse hide-it show"
                }
              >
                <p className="mb-0 faq-content">
                  Arbitrum is a layer-2 scaling solution for Ethereum that has been gaining popularity in the blockchain community. It is designed to provide faster and cheaper transactions while maintaining the security and decentralization of the Ethereum network. At our project, we have decided to implement Arbitrum as our first chain for several reasons.
                  <br /><br />

                  First and foremost, Arbitrum offers significant improvements in transaction speed and cost. As a layer-2 solution, it uses off-chain computation to process transactions, which reduces the load on the Ethereum network and results in faster and cheaper transactions. This is especially important for our project, which involves a high volume of transactions and requires fast and efficient processing.
                  <br /><br />

                  Secondly, Arbitrum is highly compatible with Ethereum, which makes it easy to integrate with existing Ethereum-based applications and smart contracts. This means that we can leverage the existing Ethereum ecosystem and community to build and deploy our project, while still taking advantage of the benefits of Arbitrum's scaling solution.
                  <br /><br />

                  Another advantage of Arbitrum is its focus on security and decentralization. It uses a unique approach called Optimistic Rollups, which allows for trustless execution of smart contracts while maintaining the security and decentralization of the Ethereum network. This ensures that our project is secure and reliable, which is essential for building trust with our users.
                  <br /><br />

                  Finally, we believe that Arbitrum is well-positioned for future growth and adoption in the blockchain space. As more and more developers and projects turn to layer-2 solutions for scaling, Arbitrum is poised to become a leading player in this space. By choosing Arbitrum as our first chain, we are positioning ourselves at the forefront of this trend and positioning our project for long-term success.
                  <br /><br />

                  In conclusion, there are several reasons why we have chosen to implement Arbitrum as our first chain in our project. Its improvements in transaction speed and cost, compatibility with Ethereum, focus on security and decentralization, and potential for future growth and adoption make it an ideal choice for our needs. We are excited to be working with Arbitrum and look forward to leveraging its capabilities to build a successful and innovative project.
                </p>
              </div>
            </h2>
          </div>

          <div className="faqwrapper">
            <h2 onClick={faq8}>
              <button
                aria-controls="example-collapse-text"
                aria-expanded={-1}
                className={
                  faqEight ? "headingtitle-active" : "headingtitle-passive"
                }
                style={{ borderRadius: "0px" }}
              >
                How to Add Arbitrum to Metamask?
                <img
                  className={faqEight ? "rotate-it" : ""}
                  src="https://app.dypius.com/static/media/arrowPassive.de81a0d7.svg"
                  alt=""
                />
              </button>
              <div
                id="example-collapse-text"
                className={
                  faqEight ? "collapse  show" : "collapse hide-it show"
                }
              >
                <p className="mb-0 faq-content">
                  MetaMask is a crypto wallet that connects by default to the Ethereum mainnet. You can find the extension and mobile app on the official MetaMask website.
                  <br /><br />
                  To connect to networks like Arbitrum, you'll need to add some blockchain information to MetaMask. This includes a chain ID, custom RPC URL, and network name. To add an Arbitrum token, you’ll also need to import the correct token address.
                  &nbsp;
                  <a href="https://academy.binance.com/en/articles/how-to-add-arbitrum-to-metamask" target="_blank" style={{ color: "yellow" }}>Explore detail</a>
                </p>

              </div>
            </h2>
          </div>

          <div className="faqwrapper">
            <h2 onClick={faq9}>
              <button
                aria-controls="example-collapse-text"
                aria-expanded={-1}
                className={
                  faqNine ? "headingtitle-active" : "headingtitle-passive"
                }
                style={{ borderRadius: "0px" }}
              >
                How to Swap Tokens from Another Chain to Arbitrum Chain?

                <img
                  className={faqNine ? "rotate-it" : ""}
                  src="https://app.dypius.com/static/media/arrowPassive.de81a0d7.svg"
                  alt=""
                />
              </button>
              <div
                id="example-collapse-text"
                className={
                  faqNine ? "collapse  show" : "collapse hide-it show"
                }
              >
                <p className="mb-0 faq-content">
                  To swap tokens from another chain to Arbitrum Chain, you will need to use a bridge like <a href="https://app.multichain.org/#/router" target="_blank" style={{ color: "yellow" }}>multichain</a>
                  . The bridge acts as a connector between the two chains, allowing you to transfer your tokens from one chain to the other.
                  <br /><br />
                  Or you can withdraw tokens to the Arbitrum chain using a centralized exchange (like Binance, or Bitfinex).                  &nbsp;
                </p>

              </div>
            </h2>
          </div>

          <div className="faqwrapper">
            <h2 onClick={faq2} id="">
              <button
                aria-controls="example-collapse-text"
                aria-expanded={-1}
                className={
                  faqTwo ? "headingtitle-active" : "headingtitle-passive"
                }
                style={{ borderRadius: "0px" }}
              >
                What Powerful Team is behind YieldCat?
                <img
                  className={faqTwo ? "rotate-it" : ""}
                  src="https://app.dypius.com/static/media/arrowPassive.de81a0d7.svg"
                  alt=""
                />
              </button>
              <div
                id="example-collapse-text"
                className={faqTwo ? "collapse  show" : "collapse hide-it show"}
              >
                <p className="mb-0 faq-content">
                  In terms of the experience of the management and development teams, YieldCat boasts a team of experienced professionals with backgrounds in finance, blockchain, and software development.
                  <br /><br />
                  The team includes experts in smart contract development, decentralized finance, and user experience design. Team blockchain capabilities extend across ERC20, ERC721, ERC1155, BEP20, Solidity smart contracts, and DApps, as well as wallets and centralized/decentralized exchanges.
                  <br /><br />
                  The founders of YieldCat, have a collective 25+ years of experience in finance and have all been involved in the cryptocurrency industry since its early days their deep understanding of the blockchain ecosystem and a track record of successful project provision to third parties ensures the application of lessons learned to the YieldCat DApp.
                </p>
              </div>
            </h2>
          </div>



        </div>
      </div>
    </div>
  );
};

export default Faq;
