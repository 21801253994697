import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  hasErrors: false,
  network: "bsc",
};

// A slice for network with our three reducers
const networkSlice = createSlice({
  name: "network",
  initialState,
  reducers: {
    getNetwork: (state) => {
      state.loading = true;
    },
    getNetworkSuccess: (state, { payload }) => {
      state.network = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    getNetworkFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
  },
});

// Three actions generated from the slice
export const { getNetwork, getNetworkSuccess, getNetworkFailure } =
  networkSlice.actions;

// A selector
export const networkSelector = (state) => state.network;

// The reducer
export default networkSlice.reducer;

// Asynchronous thunk action
export function switchNetwork(network) {
  return async (dispatch) => {
    dispatch(getNetwork());

    try {
      dispatch(getNetworkSuccess(network));
    } catch (error) {
      dispatch(getNetworkFailure());
    }
  };
}
